import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  FaCheck,
  FaEdit,
  FaEye,
  FaMobileAlt,
  FaPhone,
  FaTrash,
  FaUserCircle,
  FaWindowClose,
} from "react-icons/fa";
import {
  Box,
  Button,
  Menu,
  MenuItem,
  TableBody,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Fade from "@mui/material/Fade";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import Pagination from "@mui/material/Pagination";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import FilterListIcon from "@mui/icons-material/FilterList";
// import MailBody from '../MailBody/MailBody'
// import Message from '../Message/Message';



const AccountantDashboard = () => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setValidated(false);
  };
  const [Validated, setValidated] = useState(false);

  const [open1, setOpen1] = useState(false);

  const formValidation = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      add_primary();
    }

    setValidated(true);
  };

  const [al, setAl] = useState([]);

  const leadDataGet = () => {
    axios
      .post("https://buildfastvapi.emedha.in/api/v1/getsaleexc")
      .then((res) => {
        if (res.data.status === 200) {
          setAl(res.data.data);
          console.log(res.data.data);
        }
      });
  };

  React.useEffect(() => {
    leadDataGet();
    getlm();
    customerObjsi();
    customerObjsib();
    customerObj();
  }, []);

  const [data, setData] = useState([]);

  const getlm = () => {
    axios.get("https://buildfastvapi.emedha.in/api/v1/lead-data").then((res) => {
      setData(res.data.data);
      console.log(res.data.data);
    });
  };

  const handlesub = () => {
    axios
      .post("https://buildfastvapi.emedha.in/api/v1/updateSalesExecutive", {
        salesExecutive: salesexct,
        rowsToUpdate,
      })
      .then((res) => {
        handleClose();
        // setviewData(id)
        setOpen(false);
        getlm();
        window.location.reload();
        Swal.fire({
          icon: "success",
          title: "Successfull !",
          text: " Data added successfully !",
          confirmButtonColor: "rgb(126, 110, 228)",
          cancelButtonColor: "#d33",
          showCancelButton: false,
          confirmButtonText: "Ok",
        }).then((result) => {
          if (result.value) {
            return;
          }
        });
      });
  };

  const handleCheckboxChange = (event, itemId) => {
    if (event.target.checked) {
      setRowsToUpdate((prevRows) => [...prevRows, itemId]);
    } else {
      setRowsToUpdate((prevRows) => prevRows.filter((id) => id !== itemId));
    }
  };

  const [rowsToUpdate, setRowsToUpdate] = useState([]);
  const [salesexct, setSalexct] = useState("");

  const leadDataSearch = (text) => {
    axios
      .post("https://buildfastvapi.emedha.in/api/v1/search-lead", {
        text,
      })
      .then((res) => {
        setData(res.data);
      });
  };

  const [page, setPage] = useState(5);
  const [cpage, setCpage] = useState(1);

  const pagehandle = (e, val) => {
    setCpage(val);
  };

  const indexOflpage = cpage * page;
  const indexOffpage = indexOflpage - page;
  const currentpost = data.slice(indexOffpage, indexOflpage);

  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [customerRessi, setCustomerResponsesi] = React.useState([]);

  const customerObjsi = () => {
    axios
      .get("https://buildfastvapi.emedha.in/create-customer/api/v1/getsitform")
      .then((res) => {
        setCustomerResponsesi(res.data);
      });
  };

  const [customerRessib, setCustomerResponsesib] = React.useState([]);

  const customerObjsib = () => {
    axios
      .get("https://buildfastvapi.emedha.in/create-customer/api/v1/getblockform")
      .then((res) => {
        setCustomerResponsesib(res.data);
      });
  };

  const [customerRes, setCustomerResponse] = React.useState([]);

  const customerObj = () => {
    axios
      .get("https://buildfastvapi.emedha.in/create-customer/api/v1/customers")
      .then((res) => {
        setCustomerResponse(res.data);
      });
  };

  const [fval, setFval] = React.useState("");
  const [showOptions, setShowOptions] = useState(false);

  const [response, setResponse] = useState([]);

  const get_account = () => {
    axios
      .get("https://buildfastvapi.emedha.in/account-setting/api/v1/get-account")
      .then((res) => {
        if (res.data.status === 200) {
          setResponse(res.data.data);
          sessionStorage.setItem("project_name", res.data.data.ProjectName);
        }
      });
  };

  React.useEffect(() => {
    get_account();
  }, []);

  console.log(sessionStorage.getItem("project_name"));

  const PrName = sessionStorage.getItem("ProjectName");
  console.log(PrName);

  const [tickets, setTickets] = useState([]);

  React.useEffect(() => {
    axios
      .get("https://buildfastvapi.emedha.in/internal/api/v1/api/tickets")
      .then((response) => {
        const crmTickets = response.data.filter(
          (ticket) => ticket.receiver === "Accountant"
        );
        setTickets(crmTickets);
      })
      .catch((error) => console.error("Error fetching tickets:", error));
  }, []);

  const handleReply = (ticketNumber) => {
    console.log("Reply to ticket:", ticketNumber);
  };

  const handleCancel = (ticketNumber) => {
    console.log("Close ticket:", ticketNumber);
  };

  const handleSubmit = (ticketNumber) => {
    console.log("Submit ticket:", ticketNumber);
  };

  
  const handleTicketClosed = (ticketNumber) => {
    setTickets(tickets.filter(ticket => ticket.ticket_number !== ticketNumber));
  };

  return (
    <>
      <div>
        <h1 style={{color:'black'}}>Accountant Dashboard</h1>
        {/* <MailBody /> */}
        {/* <h2  style={{color:'black'}}>Tickets</h2> */}
        <ul>
          {/* {tickets.map((ticket) => (
            <Message
              key={ticket.id}
              ticket={ticket}
              onReply={handleReply}
              onCancel={handleCancel}
              onSubmit={handleSubmit}
              onTicketClosed={handleTicketClosed}
            />
          ))} */}
        </ul>
      </div>
    </>
  );
};

export default AccountantDashboard;
