import React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import CircularProgress from '@mui/material/CircularProgress';

import {
  Box,
  Button,
  Collapse,
  Grid,
  Menu,
  MenuItem,
  styled,
  TableBody,
  TablePagination,
  TextField,
  Typography,
} from "@mui/material";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaPhone } from "react-icons/fa";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { useState } from "react";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import Row from "react-bootstrap/Row";
import {
  FaBlenderPhone,
  FaEdit,
  FaPhoneSquare,
  FaTrash,
  FaEye,
  FaWindowClose,
} from "react-icons/fa";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Fade from "@mui/material/Fade";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import axios from "axios";
import Pagination from "@mui/material/Pagination";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import moment from "moment";
import { FaMobileAlt } from "react-icons/fa";
import Badge from "react-bootstrap/Badge";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { FormControl } from "react-bootstrap";
import { Payment } from "@material-ui/icons";


const PreSales = () => {
  const [value, setValue] = React.useState("1");
  const [id, setId] = useState();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //   const add_blockform = () => {
  //     console.log({
  //       name: nname,
  //       mobile: nmobile,
  //       alternateMobile: alternatemob,
  //       email: nemail,
  //       permanentAddress: peraddress,
  //       addComm: addresscom,
  //       aadharNo: aadharnumber,
  //       panNo: pannumber,
  //       paySource: paymentsource,
  //       cheqNo: chequenumber,
  //       transferDetail: transferdetails,
  //       amtInWords: amountinwords,
  //       amt: Amount,
  //       tflatNo: tflatno,
  //       ttowerNo: ttowerno,
  //       salesSOurce: salessource,
  //     });
  //     const formdata = new FormData();
  //     formdata.append("name", nname);
  //     formdata.append("mobile", nmobile);
  //     formdata.append("alternateMobile", alternatemob);
  //     formdata.append("email", nemail);
  //     formdata.append("permanentAddress", peraddress);
  //     formdata.append("addComm", addresscom);
  //     formdata.append("aadharNo", aadharnumber);
  //     formdata.append("panNo", pannumber);
  //     formdata.append("paySource", paymentsource);
  //     formdata.append("cheqNo", chequenumber);
  //     formdata.append("transferDetail", transferdetails);
  //     formdata.append("amtInWords", amountinwords);
  //     formdata.append("amt", Amount);
  //     formdata.append("tflatNo", tflatno);
  //     formdata.append("ttowerNo", ttowerno);
  //     formdata.append("salesSOurce", salessource);
  //     formdata.append("sig", signature);
  //     axios
  //       .post(
  //         "https://buildfastvapi.emedha.in/create-customer/api/v1/add-blockform",
  //         formdata
  //       )
  //       .then((res) => {
  //         if (res.data.status === 200) {
  //           setOpenbf(false);
  //           customerObjsib();
  //           Swal.fire({
  //             icon: "success",
  //             title: "Success !",
  //             text: "Data is added successfully !",
  //             confirmButtonColor: "rgb(126, 110, 228)",
  //             cancelButtonColor: "#d33",
  //             showCancelButton: false,
  //             confirmButtonText: "Ok",
  //           }).then((result) => {
  //             if (result.value) {
  //               return;
  //             }
  //           });
  //         }
  //       });
  //   };


 
  const [customerRessib, setCustomerResponsesib] = React.useState([]);

  const customerObjsib = () => {
    axios
      .get("https://buildfastvapi.emedha.in/create-customer/api/v1/getblockform")
      .then((res) => {
        setCustomerResponsesib(res.data);
        console.log(res.data)
      });
  };

  console.log(customerRessib)

  const [customerRes, setCustomerResponse] = React.useState([]);

  // https://buildfastvapi.emedha.in/create-customer/api/v1/customers

  const customerObj = () => {
    axios
      .get("https://buildfastvapi.emedha.in/create-customer/api/v1/customers")
      .then((res) => {
        setCustomerResponse(res.data);
        console.log(res.data)
      });
  };

  React.useEffect(() => {
    customerObj();
    customerObjsib();
  }, []);


  console.log(customerRes)

  const [openRejectForm, setOpenReject] = React.useState();

  const handleClickOpenReject = () => {
    setOpenReject(true);
  };

  const handleCloseReject = () => {
    setOpenReject(false);
  };

  const [rejectDate, setDataForReject] = useState("");
  const [rejectComment, setCommentForReject] = useState("");

  const [Validatedtabcb, setValidatedtabcb] = useState(false);

  const formValidationReject = async(event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
       try {
        await axios.post('https://buildfastvapi.emedha.in/create-customer/api/v1/block/reject', {
          id,
          rejectDate: rejectDate,
          rejectComment: rejectComment,
       
        });
        setDataForReject({});
        setCommentForReject('');
        setDataForReject('')
        setOpenReject(false);
        alert("Block Successfully Rejected")
        window.location.reload();
      } catch (error) {
        console.error("Error approving blocked customer:", error);
        alert("Error approving blocked customer:", error)
      }
      setValidatedtabcb(false);
    };
  };

  const [openBookRejectForm, setOpenBookRejectForm] = useState();

  const handleClickBookingReject = () => {
    setOpenBookRejectForm(true);
  };

  const handleCloseBookReject = () => {
    setOpenBookRejectForm(false);
  };

  const [bookingRejectDate, setDataForBookingReject] = useState("");
  const [bookingRejectComment, setCommentForBookingReject] = useState("");

  const formValidationRejectBook = async(event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      try {
        await axios.post('https://buildfastvapi.emedha.in/create-customer/api/v1/book/reject', {
          id,
          rejectDate: bookingRejectDate,
          rejectComment: bookingRejectComment,
       
        });
        setDataForBookingReject({});
        setCommentForBookingReject('');
        setDataForBookingReject('')
        setOpenBookRejectForm(false);
        alert("Book Successfully Rejected")
        window.location.reload();
      } catch (error) {
        console.error("Error approving blocked customer:", error);
        alert("Error approving booked customer:", error)
      }
      setValidatedtabcb(false);
    };
    
  };

  const [blockApproveDate, setDataForBlockApprove] = useState("");
  const [blockApproveComment, setCommentForBlockApprove] = useState("");
  const [blockTransactionId,setTransactionIdForApproveBlock]=useState("")
  const [blockedCustomers, setBlockedCustomers] = useState(customerRessib);

  const [ValidatedtaBlockApproveForm, setValidatedBlockApproveForm] = useState(false);

  const [openApproveBlockForm, setOpenApproveBlockForm] = useState();

  const handleClickOpenApproveBlock = () => {
    setOpenApproveBlockForm(true);
  };

  const handleCloseApproveBlockForm = () => {
    setOpenApproveBlockForm(false);
  };

  const formValidationForBlockApprove =async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      try {
        await axios.post('https://buildfastvapi.emedha.in/create-customer/api/v1/block/approve', {
          id,
          approvalDate: blockApproveDate,
          comment: blockApproveComment,
          transactionId:blockTransactionId
        });

        // https://buildfastvapi.emedha.in/create-customer/api/v1/block/approve
        // Update the blocked and booking lists
        // const updatedBlockedCustomers = blockedCustomers.filter(item => item.Id !== approveData.Id);
        // const updatedCustomers = [...customers, { ...approveData, status: 'Approved' }];
        // setBlockedCustomers(updatedBlockedCustomers);
        // setCustomers(updatedCustomers);
        setApproveData({});
        setCommentForBlockApprove('');
        setDataForBlockApprove('')
        setOpenApproveBlockForm(false);
        alert("Block Successfully Approved")
        window.location.reload();
      } catch (error) {
        console.error("Error approving blocked customer:", error);
        alert("Error approving blocked customer:", error)
      }
      setValidatedBlockApproveForm(false);
    }
    
  };


   
  const [clickedName, setClickedName] = useState(sessionStorage.getItem("clickedName") || "")

  const handleRowClickItem = (itemName) => {
    sessionStorage.setItem("clickedName", itemName);
    setClickedName(itemName);
    console.log("Stored clicked name:", itemName); // Debug log
  };

  // const clickedName = sessionStorage.getItem("clickedName");
  // console.log("Retrieved clicked name:", clickedName); // Debug log

  React.useEffect(() => {
    const storedName = sessionStorage.getItem("clickedName");
    if (storedName) {
      setClickedName(storedName);
    }
  }, []);


  const filteredItems = clickedName
    ? customerRessib.filter((item) => item.name === clickedName)
    : [];

    console.log(filteredItems)





  const [bookDateApprove, setDataForApproveBooking] = useState("");
  const [bookCommentApprove, setCommentForApproveBooking] = useState("");
  const [bookTransactionId,setTransactionIdForApproveBooking]= useState("");

  const [openApproveBookingForm, setApproveBookingForm] = useState("");
  
  const [ValidatedtaBookingForm, setValidatedBookingForm] = useState(false);
  const [approveData, setApproveData] = useState({});

  const handleClickBookingApprove = (item) => {
    setApproveBookingForm(true);
    setApproveData(item);
  };

  const handleCloseApproveBookingForm = () => {
    setApproveBookingForm(false);
  };

  const formValidationApproveBookingForm = async(event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
     

      //https://buildfastvapi.emedha.in/create-customer/api/v1/book/approve

      try{
        await axios.post('https://buildfastvapi.emedha.in/create-customer/api/v1/book/approve', {
          id,
          approvalDate: bookDateApprove,
          comment: bookCommentApprove,
          // transactionId:bookTransactionId
        });
       
       
        setApproveData({});
        setCommentForApproveBooking('');
        setDataForApproveBooking('')
        setApproveBookingForm(false)
        alert("Booking Successfully Approved")
        window.location.reload();
      } catch (error) {
        // Handle error, e.g., show error message
        console.error("Error approving booking:", error);
        alert("Error approving booking customer:", error)
      }
      setValidatedBookingForm(false);
    }
    
  };


  const getCurrentDateTime = () => {
    const current = new Date();
    const year = current.getFullYear();
    const month = String(current.getMonth() + 1).padStart(2, "0");
    const day = String(current.getDate()).padStart(2, "0");
    const hours = String(current.getHours()).padStart(2, "0");
    const minutes = String(current.getMinutes()).padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  // Set the default date and time when the component mounts
  React.useEffect(() => {
    setDataForBlockApprove(getCurrentDateTime());
    setDataForApproveBooking(getCurrentDateTime())
    
  }, []);

  const [openRows, setOpenRows] = useState({});

  // Toggle row expansion
  const handleRowClick = (id) => {
    setOpenRows(prevState => ({
      ...prevState,
      [id]: !prevState[id]
    }));
  };

  const [clickedNameBook, setClickedNameBook] = useState(sessionStorage.getItem("clickedNamebOOK") || "")

  const handleRowClickItemBook = (itemName) => {
    sessionStorage.setItem("clickedNamebOOK", itemName);
    setClickedNameBook(itemName);
    console.log("Stored clicked name:", itemName); // Debug log
  };

  // const clickedName = sessionStorage.getItem("clickedName");
  // console.log("Retrieved clicked name:", clickedName); // Debug log

  React.useEffect(() => {
    const storedName = sessionStorage.getItem("clickedNamebOOK");
    if (storedName) {
      setClickedNameBook(storedName);
    }
  }, []);


  const filteredItemsBook = clickedNameBook
    ? customerRes.filter((item) => item.customerName === clickedNameBook)
    : [];

    console.log(filteredItemsBook)


  // const updatePayment = (id, paymentType, status, approvalDate, comment) => {
  //   const url = `http://localhost:3003/api/payments/${id}/${paymentType}/${status}`;
  //   axios.post(url, { approvalDate, comment })
  //     .then(response => {
  //       console.log('Success:', response.data);
  //       // Update local state or refetch data
  //     })
  //     .catch(error => {
  //       console.error('Error:', error);
  //     });
  // };

  // https://buildfastvapi.emedha.in/create-customer/api/v1/api/payments/${id}/${type}/${action}

  const updatePayment = async (id, type, action, approvalDate, comment) => {
    try {
      const response = await fetch(`https://buildfastvapi.emedha.in/create-customer/api/v1/api/payments/${id}/${type}/${action}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ approvalDate, comment }),
      });
      const result = await response.json();
      if (response.ok) {
        alert(`Success: ${result.message}`);
      } else {
        alert(`Failed to update payment: ${result.error}`);
      }
    } catch (error) {
      alert(`Error: ${error.message}`);
    }
  };
  
  
  const [inputValues, setInputValues] = useState({});

  const handleInputChange = (id, type, field, value) => {
    setInputValues(prev => ({
      ...prev,
      [id]: {
        ...prev[id],
        [`${type}_${field}`]: value
      }
    }));
  };

  const [paymentStatus, setPaymentStatus] = useState({});


  const handleApproveReject = async (id, type, action) => {
    const item = inputValues[id] || {};
    const approvalDate = item[`${type}_approvalDate`] || '';
    const comment = item[`${type}_comment`] || '';

    await updatePayment(id, type, action, approvalDate, comment);
    setPaymentStatus(prevStatus => ({
      ...prevStatus,
      [id]: {
        ...prevStatus[id],
        [type]: action === 'approve' ? 'Approved' : 'Rejected',
      },
    }));
  };


  const customerName = filteredItemsBook.customerName;
  const payments = filteredItemsBook.payments || [];

  console.log(customerName)
  console.log(payments)


  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // // const handleApproval = async (itemIndex, paymentIndex) => {
  // //   setLoading(true);
  // //   setError(null);

  // //   const payment = filteredItemsBook[itemIndex].payments[paymentIndex];

  // //   try {
  // //     const response = await axios.post('http://localhost:3003/api/approvePayment', {
  // //       id: payment.id,
     
  // //       paidStatus: 'Approved'
  // //     });

  // //     if (response.status === 200) {
  // //       const updatedFilteredItemsBook = [...filteredItemsBook];
  // //       updatedFilteredItemsBook[itemIndex].payments[paymentIndex].paidStatus = 'Approved';
       
  // //       setFilteredItemsBook(updatedFilteredItemsBook);
  // //     }
  // //   } catch (err) {
  // //     setError('Failed to approve payment. Please try again.');
  // //   } finally {
  // //     setLoading(false);
  // //   }
  // // };

  // const handleApproval = async (itemIndex, paymentIndex) => {
  //   setLoading(true);
  //   setError(null);
  
  //   const payment = filteredItemsBook[itemIndex].payments[paymentIndex];
  
  //   try {
  //     const response = await axios.post('http://localhost:3003/api/approvePayment', {
  //       id: payment.id,
  //       paidStatus: 'Approved'
  //     });
  
  //     console.log('Response:', response); // Debugging line
  
  //     if (response.status === 200) {
  //       const updatedFilteredItemsBook = [...filteredItemsBook];
  //       updatedFilteredItemsBook[itemIndex].payments[paymentIndex].paidStatus = 'Approved';
  //       updatedFilteredItemsBook[itemIndex].payments[paymentIndex].status = 'Approved';
  
       
  //     } else {
  //       setError('Unexpected response status. Please try again.');
  //     }
  //   } catch (err) {
  //     console.error('Error approving payment:', err.response ? err.response.data : err.message);
  //     setError('Failed to approve payment. Please try again.');
  //   } finally {
  //     setLoading(false);
  //   }
  // };


  // const handleRejection = async (itemIndex, paymentIndex) => {
  //   setLoading(true);
  //   setError(null);

  //   const payment = filteredItemsBook[itemIndex].payments[paymentIndex];

  //   try {
  //     const response = await axios.post('http://localhost:3003/api/rejectPayment', {
  //       id: payment.id,
   
  //       paidStatus: 'Rejected'
  //     });

  //     if (response.status === 200) {
  //       const updatedFilteredItemsBook = [...filteredItemsBook];
  //       updatedFilteredItemsBook[itemIndex].payments[paymentIndex].paidStatus = 'Rejected';
     
  //       setFilteredItemsBook(updatedFilteredItemsBook);
  //     }
  //   } catch (err) {
  //     setError('Failed to reject payment. Please try again.');
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const DownloadLink = styled('a')({
    display: 'inline-block',
    textDecoration: 'none',
    color: '#007bff',
    margin: '0.5rem 0',
  });



  // Function to handle approval
  const handleApproval = async (itemIndex, paymentIndex) => {
    setLoading(true);
    setError(null);
  
    const payment = filteredItemsBook[itemIndex].payments[paymentIndex];

    // https://buildfastvapi.emedha.in/create-customer/api/v1/api/approvePayment
  
    try {
      const response = await axios.post('https://buildfastvapi.emedha.in/create-customer/api/v1/api/approvePayment', {
        id: payment.id,
        paidStatus: 'Approved'
      });
  
      if (response.status === 200) {
        // Update state to remove the payment
        const updatedFilteredItemsBook = [...filteredItemsBook];
        updatedFilteredItemsBook[itemIndex].payments = updatedFilteredItemsBook[itemIndex].payments.filter(
          (_, index) => index !== paymentIndex
        );
        
        // Optionally, remove the item if it has no payments left
        if (updatedFilteredItemsBook[itemIndex].payments.length === 0) {
          updatedFilteredItemsBook.splice(itemIndex, 1);
        }
  
        // setFilteredItemsBook(updatedFilteredItemsBook);
        setApproveBookingForm(false); // Close dialog after approval
        alert('Payment approved successfully.'); // Success alert
      } else {
        setError('Unexpected response status. Please try again.');
      }
    } catch (err) {
      console.error('Error approving payment:', err.response ? err.response.data : err.message);
      setError('Failed to approve payment. Please try again.');
    } finally {
      setLoading(false);
    }
  };
  
  // Function to handle rejection
  const handleRejection = async (itemIndex, paymentIndex) => {
    setLoading(true);
    setError(null);
  
    const payment = filteredItemsBook[itemIndex].payments[paymentIndex];

    // https://buildfastvapi.emedha.in/create-customer/api/v1/api/rejectPayment
  
    try {
      const response = await axios.post('https://buildfastvapi.emedha.in/create-customer/api/v1/api/rejectPayment', {
        id: payment.id,
        paidStatus: 'Rejected'
      });
  
      if (response.status === 200) {
        // Update state to remove the payment
        const updatedFilteredItemsBook = [...filteredItemsBook];
        updatedFilteredItemsBook[itemIndex].payments = updatedFilteredItemsBook[itemIndex].payments.filter(
          (_, index) => index !== paymentIndex
        );
        
        // Optionally, remove the item if it has no payments left
        if (updatedFilteredItemsBook[itemIndex].payments.length === 0) {
          updatedFilteredItemsBook.splice(itemIndex, 1);
        }
  
        alert('Payment rejected successfully.'); 
        setApproveBookingForm(false); // Close dialog after rejection
      } else {
        setError('Unexpected response status. Please try again.');
      }
    } catch (err) {
      console.error('Error rejecting payment:', err.response ? err.response.data : err.message);
      setError('Failed to reject payment. Please try again.');
    } finally {
      setLoading(false);
    }
  };




  const handleRowClickToggle = (id) => {
    setOpenRows(prev => ({ ...prev, [id]: !prev[id] }));
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  // Handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Paginated data
  const filteredCustomerRes = customerRes
    .filter(item => item.payments.some(payment => payment.paidStatus === 'Rejected' || payment.paidStatus === 'Approved'));

  const paginatedData = filteredCustomerRes
    .flatMap(item => item.payments
      .filter(payment => payment.paidStatus === 'Rejected' || payment.paidStatus === 'Approved')
      .map(payment => ({ ...payment, customerName: item.customerName, flatNo: item.flatNo }))
    )
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);


    const filteredTransactionDetails = customerRessib
    .filter(item => {
      // Only show detailed transactions for blocked items
      return (
        item.overall_status === 'Approved' || item.overall_status === 'Rejected' &&
        (item.bank_amtPaid || item.cheque_amtPaid || item.online_amtPaid || item.upi_amtPaid)
      );
    });

  // Paginated data
  const paginatedTransactionDetails = filteredTransactionDetails
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    const setCloseFunction=()=>{
      window.location.reload()
    }
  

    const ProjectName=sessionStorage.getItem("ProjectName")

  return (
    <>
      <div>
        {
          <div className="leadManagementTable">
            <Typography
              style={{
                color: "rgba(41, 16, 105, 1)",
                marginBottom: 6,
                marginLeft: 15.5,
                fontSize: 18,
                color: "gray",
                fontWeight: "500",
                textAlign: "center",
              }}
            >
              PreSales Details
            </Typography>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: 5,
              }}
            ></div>
            <Paper elevation={0}>
              {/* <TableContainer sx={{ height: 95 + "vh", p: 0.3, pt: 2 }} > */}

              <Box sx={{ width: "100%", typography: "body1" }}>
                <TabContext value={value}>
                  <Box
                    sx={{
                      borderBottom: 1,
                      borderColor: "divider",
                      color: "rgb(86, 63, 146)",
                    }}
                  >
                    <TabList
                      onChange={handleChange}
                      aria-label="lab API tabs example"
                      indicatorColor="secondary"
                    >
                      <Tab
                        label="Blocked"
                        value="1"
                        style={{ color: "rgb(86, 63, 146)" }}
                      />
                      <Tab
                        label="Booked"
                        value="2"
                        style={{ color: "rgb(86, 63, 146)" }}
                      />
                    </TabList>
                  </Box>
                
                  <TabPanel value="1">
  <div style={{ width: "58rem" }}>
    <Typography
      style={{
        color: "rgba(41, 16, 105, 1)",
        marginBottom: 3,
        marginLeft: 15.5,
        fontSize: 18,
        color: "gray",
        fontWeight: "500",
        textAlign: "center",
      }}
    >
      Blocked
    </Typography>
    <div style={{ fontWeight: "bold" }}>
      Total Blocked: {customerRessib.filter(item => {
        // Exclude items where overall_status is 'Approved'
        if (item.overall_status === 'Approved') {
          return false;
        }
        
        // Check if any payment amount is provided
        const hasPayment = 
          item.bank_amtPaid ||
          item.cheque_amtPaid ||
          item.online_amtPaid ||
          item.upi_amtPaid;
        
        // Check if any payment method has been approved
        const hasApprovedPayment = 
          (item.bank_status === 'Approved' && item.bank_amtPaid) ||
          (item.cheque_status === 'Approved' && item.cheque_amtPaid) ||
          (item.online_status === 'Approved' && item.online_amtPaid) ||
          (item.upi_status === 'Approved' && item.upi_amtPaid);

          const projectnames=item.ProjectName===ProjectName
        
        // Exclude items where all payment amounts are missing or all payments are approved
        return hasPayment && !hasApprovedPayment && projectnames;
      }).length}
    </div>

    <Table style={{ position: "relative", top: "1.3rem" }}>
      <TableHead sx={{ borderTop: "1px solid lightgray" }}>
        <TableRow>
          <TableCell
            align={"start"}
            style={{
              minWidth: 0,
              fontSize: 14,
              fontWeight: "600",
            }}
          >
            Name
          </TableCell>
          <TableCell
            align={"left"}
            style={{ top: 57, fontWeight: "600" }}
          >
            Mobile
          </TableCell>

          <TableCell
            align={"left"}
            style={{ top: 21, fontWeight: "600" }}
          >
            Aadhar
          </TableCell>

          <TableCell
            align={"left"}
            style={{ top: 21, fontWeight: "600" }}
          >
            Address
          </TableCell>

          <TableCell
            align={"center"}
            style={{ top: 21, fontWeight: "600", width: 18 }}
          >
            Action
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {customerRessib &&
          customerRessib
            .filter(item => {
              // Exclude items where overall_status is 'Approved'
              if (item.overall_status === 'Approved') {
                return false;
              }
              
              // Check if any payment amount is provided
              const hasPayment = 
                item.bank_amtPaid ||
                item.cheque_amtPaid ||
                item.online_amtPaid ||
                item.upi_amtPaid;
              
              // Check if any payment method has been approved
              const hasApprovedPayment = 
                (item.bank_status === 'Approved' && item.bank_amtPaid) ||
                (item.cheque_status === 'Approved' && item.cheque_amtPaid) ||
                (item.online_status === 'Approved' && item.online_amtPaid) ||
                (item.upi_status === 'Approved' && item.upi_amtPaid);


                const ProjectNames=item.ProjectName===ProjectName
              
              // Exclude items where all payment amounts are missing or all payments are approved
              return hasPayment && !hasApprovedPayment && ProjectNames;
            })
            .map(item => (
              <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
                key={item.id}
              >
                <TableCell
                  align="start"
                  style={{ top: 57, fontSize: 12 }}
                >
                  {item.name}
                </TableCell>
                <TableCell
                  align="start"
                  style={{ top: 57, fontSize: 12 }}
                >
                  <button
                    style={{
                      fontSize: 10.7,
                      border: "2px solid rgb(126, 110, 228)",
                      backgroundColor: "white",
                      borderRadius: "1.2rem",
                      cursor: "auto",
                    }}
                  >
                    <FaMobileAlt
                      style={{
                        marginRight: 3,
                        color: "rgb(126, 110, 228)",
                        marginTop: "-0.16rem",
                        fontSize: 9,
                      }}
                    />
                    {item.mobile}
                  </button>
                </TableCell>

                <TableCell
                  align="start"
                  style={{ top: 57, fontSize: 12 }}
                >
                  {item.aadharNo}
                </TableCell>

                <TableCell
                  align="start"
                  style={{ top: 57, fontSize: 12 }}
                >
                  {item.permanentAddress}
                </TableCell>
                <TableCell
                  align="start"
                  style={{
                    top: 57,
                    fontSize: 12,
                    color: "rgb(126, 110, 228) ",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <Badge
                      bg="success"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleClickOpenApproveBlock();
                        handleRowClickItem(item.name);
                        setId(item.id);
                      }}
                    >
                      Approve
                    </Badge>
                  </div>
                </TableCell>
              </TableRow>
            ))}
      </TableBody>
    </Table>

    {/* New Table for Transaction Details */}
    <Typography
      style={{
        color: "rgb(126, 110, 228)",
        marginBottom: 3,
        marginLeft: 15.5,
        fontSize: 18,
        marginTop:35,
        fontWeight: "500",
        textAlign: "center",
        marginTop: "2rem",
      }}
    >
      Transaction Details
    </Typography>
    <Table style={{ position: "relative", top: "1.3rem" ,marginBottom:30}}>
      <TableHead sx={{ borderTop: "1px solid lightgray" }}>
        <TableRow>
          <TableCell
            align={"start"}
            style={{
              minWidth: 0,
              fontSize: 14,
              fontWeight: "600",
            }}
          >
            Name
          </TableCell>
          <TableCell
            align={"left"}
            style={{ top: 57, fontWeight: "600" }}
          >
            Email
          </TableCell>

          <TableCell
            align={"left"}
            style={{ top: 21, fontWeight: "600" }}
          >
            Flat No
          </TableCell>

          <TableCell
            align={"left"}
            style={{ top: 21, fontWeight: "600" }}
          >
            Payment Transactions
          </TableCell>

          <TableCell
            align={"center"}
            style={{ top: 21, fontWeight: "600" }}
          >
            Status
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
      {paginatedTransactionDetails.filter((item=> item.ProjectName===ProjectName))
            .map(item => (
              <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
                key={item.id}
              >
                <TableCell
                  align="start"
                  style={{ top: 57, fontSize: 12 }}
                >
                  {item.name}
                </TableCell>
                <TableCell
                  align="start"
                  style={{ top: 57, fontSize: 12 }}
                >
                  {item.email}
                </TableCell>

                <TableCell
                  align="start"
                  style={{ top: 57, fontSize: 12 }}
                >
                  {item.tflatNo}
                </TableCell>

                <TableCell
                  align="start"
                  style={{ top: 57, fontSize: 12 }}
                >
                  {item.bank_amtPaid && `Bank: ₹${item.bank_amtPaid}`}
                  {item.cheque_amtPaid && `, Cheque: ₹${item.cheque_amtPaid}`}
                  {item.online_amtPaid && `, Online: ₹${item.online_amtPaid}`}
                  {item.upi_amtPaid && `, UPI: ₹${item.upi_amtPaid}`}
                </TableCell>
                <TableCell
  align="start"
  style={{
    top: 57,
    fontSize: 12,
    color: item.overall_status === 'Approved' ? 'green' : item.overall_status === 'Rejected' ? 'red' : 'black',
  }}
>
  {item.overall_status}
</TableCell>

              </TableRow>
            ))}
      </TableBody>
    </Table>
    <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={filteredTransactionDetails.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
  </div>
</TabPanel>


<TabPanel value="2">
      <div style={{ width: "58rem" }}>
        <Typography
          style={{
            color: "rgba(41, 16, 105, 1)",
            marginBottom: 3,
            marginLeft: 15.5,
            fontSize: 18,
            color: "gray",
            fontWeight: "500",
            textAlign: "center",
          }}
        >
          Booked
        </Typography>

        <div style={{ fontWeight: "bold" }}>
          Total Booked: {customerRes
            .filter(item => item.payments.some(payment => payment.paidStatus === null) && item.ProjectName===ProjectName)
            .length}
        </div>

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell  align={"left"}
            style={{ top: 21, fontWeight: "600" }}>Name</TableCell>
                <TableCell  align={"left"}
            style={{ top: 21, fontWeight: "600" }}>Mobile</TableCell>
                <TableCell  align={"left"}
            style={{ top: 21, fontWeight: "600" }}>Loan</TableCell>
                <TableCell  align={"left"}
            style={{ top: 21, fontWeight: "600" }}>Amount Paid</TableCell>
                <TableCell  align={"left"}
            style={{ top: 21, fontWeight: "600" }}>Payments</TableCell>
                <TableCell  align={"left"}
            style={{ top: 21, fontWeight: "600" }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {customerRes && customerRes
                .filter(item => item.payments.some(payment => payment.paidStatus === null) && item.ProjectName===ProjectName) // Filter for 'Received' payments
                .map((item) => {
                  const filteredPayments = item.payments.filter(payment => payment.paidStatus === null);
                  const totalAmountPaid = filteredPayments.reduce((sum, payment) => sum + parseFloat(payment.finalAmount || 0), 0);

                  return (
                    <React.Fragment key={item.id}>
                      <TableRow hover role="checkbox" tabIndex={-1} onClick={() => handleRowClickToggle(item.id)}>
                        <TableCell align="start" style={{ top: 57, fontSize: 12 }}>
                          {item.customerName}
                        </TableCell>
                        <TableCell align="start" style={{ top: 57, fontSize: 12 }}>
                          <button
                            style={{
                              fontSize: 10.7,
                              border: "2px solid rgb(126, 110, 228)",
                              backgroundColor: "white",
                              borderRadius: "1.2rem",
                              cursor: "auto",
                            }}
                          >
                            <FaMobileAlt
                              style={{
                                marginRight: 3,
                                color: "rgb(126, 110, 228)",
                                marginTop: "-0.16rem",
                                fontSize: 9,
                              }}
                            />
                            {item.mobileNumber}
                          </button>
                        </TableCell>
                        <TableCell align="start" style={{ top: 57, fontSize: 12 }}>
                          {item.loan}
                        </TableCell>
                        <TableCell align="start" style={{ top: 57, fontSize: 12 }}>
                          {totalAmountPaid}
                        </TableCell>
                        <TableCell align="start" style={{ top: 57, fontSize: 12 }}>
                          {filteredPayments.length > 0 ? 'Show Details' : 'No Payments'}
                        </TableCell>
                        <TableCell align="start" style={{ top: 57, fontSize: 12 }}>
                          <div style={{ display: "flex" }}>
                            <Badge
                              bg="success"
                              style={{ cursor: "pointer" }}
                              onClick={() => { handleClickBookingApprove();  setId(item.id); handleRowClickItemBook(item.customerName); }}
                            >
                              Approve
                            </Badge>
                          </div>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={6} style={{ paddingBottom: 0, paddingTop: 0 }}>
                          <Collapse in={openRows[item.id]}>
                            <Box margin={1} style={{ maxHeight: '200px', overflowY: 'auto' }}>
                              <Table size="small">
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Pay Source</TableCell>
                                    <TableCell>Amount</TableCell>
                                    <TableCell>Transaction ID</TableCell>
                                    <TableCell>Details</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {filteredPayments.map((payment) => (
                                    <TableRow key={payment.id}>
                                      <TableCell>{payment.paySource}</TableCell>
                                      <TableCell>{payment.finalAmount}</TableCell>
                                      <TableCell>{payment.transactionId}</TableCell>
                                      <TableCell>{payment.transferDetail || payment.cheqNo}</TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>

        <Typography
          style={{
            color: "rgb(126, 110, 228)",
            marginTop: 20,
            marginBottom: 3,
            marginLeft: 15.5,
            fontSize: 18,
            marginTop:35,
            fontWeight: "500",
            textAlign: "center",
          }}
        >
         Transaction Details
        </Typography>

        <TableContainer>
          <Table>
            <TableHead sx={{ borderTop: "1px solid lightgray" }}>
              <TableRow>
                <TableCell  align={"left"}
            style={{ top: 21, fontWeight: "600" }}>Name</TableCell>
                <TableCell  align={"left"}
            style={{ top: 21, fontWeight: "600" }}>Flat No</TableCell>
                <TableCell  align={"left"}
            style={{ top: 21, fontWeight: "600" }}>Payment Transactions</TableCell>
                <TableCell  align={"left"}
            style={{ top: 21, fontWeight: "600" }}>Amount</TableCell>
                <TableCell  align={"left"}
            style={{ top: 21, fontWeight: "600" }}>Paid Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {customerRes && customerRes
                .filter(item => item.payments.some(payment => payment.paidStatus === 'Rejected' || payment.paidStatus === 'Approved') && item.ProjectName===ProjectName)
                .map((item) => {
                  const filteredPayments = item.payments.filter(payment => payment.paidStatus === 'Rejected' || payment.paidStatus === 'Approved');
                  
                  return (
                    <React.Fragment key={item.id}>
                      {filteredPayments.map((payment) => (
                        <TableRow key={payment.transactionId}>
                          <TableCell>{item.customerName}</TableCell>
                          <TableCell>{item.flatNo}</TableCell>
                          <TableCell>{payment.transactionId}</TableCell>
                          <TableCell>{payment.finalAmount}</TableCell>
                          <TableCell>{payment.paidStatus}</TableCell>
                        </TableRow>
                      ))}
                    </React.Fragment>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredCustomerRes.flatMap(item => item.payments).filter(payment => payment.paidStatus === 'Rejected' || payment.paidStatus === 'Approved').length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />

      </div>
    </TabPanel>
                </TabContext>
              </Box>
            </Paper>
          </div>
        }
      </div>

      {/*Blocked Approve Form*/}

      <Dialog
        style={{ borderTop: "12px solid rgb(126, 110, 228)" }}
        maxWidth="xl"
        open={openApproveBlockForm}
        onClose={handleCloseApproveBlockForm}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <div style={{ width: "37rem" }}>
            <Form
              noValidate
              validated={ValidatedtaBlockApproveForm}
              onSubmit={formValidationForBlockApprove}
            >
              <div className="mb-4 mt-1">
                <Typography
                  style={{
                    textAlign: "center",
                    fontWeight: "500",
                    color: "rgb(126, 110, 228)",
                    fontSize: 21,
                  }}
                >
                  Blocked Approve
                </Typography>
              </div>
              <TableContainer component={Paper} style={{ marginBottom: '1rem' }}>
              <Table>
      <TableHead>
        <TableRow>
          <TableCell>Customer Name</TableCell>
          <TableCell >Total Amount</TableCell>
          <TableCell >GST Percentage</TableCell>
          
          <TableCell >GST Amount</TableCell>
          
        
        </TableRow>
      </TableHead>
      <TableBody>
        {filteredItems.map((row) => (
          <React.Fragment key={row.id}>
            {/* Customer Info Row */}
            <TableRow>
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.blockTotal}</TableCell>
              <TableCell>{row.gstPercentage || 'N/A'}</TableCell>
              
              <TableCell>{row.gstAmount || 'N/A'}</TableCell>
            </TableRow>

            {/* Payment Details Rows */}
            <TableRow >
              <TableCell colSpan={3}>
                <div >
                  {/* Bank Payment */}
                  {row.bank_amtPaid && paymentStatus[row.id]?.bank !== 'Approved' && paymentStatus[row.id]?.bank !== 'Rejected' && (
                    <div className="shadow mb-3 p-3">
                          <div className='d-flex flex-row align-items-center justify-content-between'>
                      <Typography>
                        Bank Payment: ₹{row.bank_amtPaid}
                        <span>Transaction ID: {row.bank_transactionId}</span>
                      </Typography>
                      {/* <a href={row.bank_screenshot} target="_blank" rel="noopener noreferrer">
                        <img src={row.bank_screenshot} alt="Bank Payment Screenshot"/>
                      </a> */}
                       <DownloadLink href={row.bank_screenshot} download target="_blank" rel="noopener noreferrer">
                       Bank Screenshot
                            </DownloadLink>
                      </div>
                      <TextField
                        type="datetime-local"
                        value={inputValues[row.id]?.bank_approvalDate || ''}
                        onChange={(e) => handleInputChange(row.id, 'bank', 'approvalDate', e.target.value)}
                        style={{ marginBottom: '8px', width: '100%' }}
                      />
                      <TextField
                        label="Bank Comment"
                        value={inputValues[row.id]?.bank_comment || ''}
                        onChange={(e) => handleInputChange(row.id, 'bank', 'comment', e.target.value)}
                        style={{ marginBottom: '8px', width: '100%' }}
                      />
                      <div style={{ display: 'flex', gap: '0.5rem' }}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleApproveReject(row.id, 'bank', 'approve')}
                         
                        >
                          Approve Bank
                        </Button>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => handleApproveReject(row.id, 'bank', 'reject')}
                         
                        >
                          Reject Bank
                        </Button>
                      </div>
                    </div>
                  )}

                  {/* Cheque Payment */}
                  {row.cheque_amtPaid && paymentStatus[row.id]?.cheque !== 'Approved' && paymentStatus[row.id]?.cheque !== 'Rejected' && (
                    <div className="shadow mb-3 p-3">
                         <div className='d-flex flex-row align-items-center justify-content-between'>
                      <Typography >
                        Cheque Payment: ₹{row.cheque_amtPaid}
                        <span>Cheque Number: {row.cheque_chequeNumber}</span>
                      </Typography>
                      {/* <a href={row.cheque_chequeImage} target="_blank" rel="noopener noreferrer">
                        <img src={row.cheque_chequeImage} alt="Cheque Image"  />
                      </a> */}
                       <DownloadLink href={row.cheque_chequeImage} download target="_blank" rel="noopener noreferrer">
                       Cheque Screenshot
                            </DownloadLink>
                      </div>
                      <TextField
                        type="datetime-local"
                        value={inputValues[row.id]?.cheque_approvalDate || ''}
                        onChange={(e) => handleInputChange(row.id, 'cheque', 'approvalDate', e.target.value)}
                        style={{ marginBottom: '8px', width: '100%' }}
                      />
                      <TextField
                        label="Cheque Comment"
                        value={inputValues[row.id]?.cheque_comment || ''}
                        onChange={(e) => handleInputChange(row.id, 'cheque', 'comment', e.target.value)}
                        style={{ marginBottom: '8px', width: '100%' }}
                      />
                      <div style={{ display: 'flex', gap: '0.5rem' }}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleApproveReject(row.id, 'cheque', 'approve')}
                        
                        >
                          Approve Cheque
                        </Button>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => handleApproveReject(row.id, 'cheque', 'reject')}
                     
                        >
                          Reject Cheque
                        </Button>
                      </div>
                    </div>
                  )}

                  {/* Online Payment */}
                  {row.online_amtPaid && paymentStatus[row.id]?.online !== 'Approved' && paymentStatus[row.id]?.online !== 'Rejected' && (
                    <div className="shadow mb-3 p-3">
                   <div className='d-flex flex-row align-items-center justify-content-between'>
                      <Typography >
                        Online Payment: ₹{row.online_amtPaid}
                        <span>Transfer Details: {row.online_transferDetails}</span>
                      </Typography>
                      {/* <a href={row.online_screenshot} target="_blank" rel="noopener noreferrer">
                        <img src={row.online_screenshot} alt="Online Payment Screenshot"  />
                      </a> */}
                       <DownloadLink href={row.online_screenshot} download target="_blank" rel="noopener noreferrer">
                       Online Screenshot
                            </DownloadLink>

                      </div>
                      <TextField
                        type="datetime-local"
                        value={inputValues[row.id]?.online_approvalDate || ''}
                        onChange={(e) => handleInputChange(row.id, 'online', 'approvalDate', e.target.value)}
                        style={{ marginBottom: '8px', width: '100%' }}
                      />
                      <TextField
                        label="Online Comment"
                        value={inputValues[row.id]?.online_comment || ''}
                        onChange={(e) => handleInputChange(row.id, 'online', 'comment', e.target.value)}
                        style={{ marginBottom: '8px', width: '100%' }}
                      />
                      <div style={{ display: 'flex', gap: '0.5rem' }}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleApproveReject(row.id, 'online', 'approve')}
                 
                        >
                          Approve Online
                        </Button>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => handleApproveReject(row.id, 'online', 'reject')}
              
                        >
                          Reject Online
                        </Button>
                      </div>
                    </div>
                  )}

                  {/* UPI Payment */}
                  {row.upi_amtPaid && paymentStatus[row.id]?.upi !== 'Approved' && paymentStatus[row.id]?.upi !== 'Rejected' && (
                    <div className="shadow mb-3 p-3">
                       <div className='d-flex flex-row align-items-center justify-content-between'>
                      <Typography >
                        UPI Payment: ₹{row.upi_amtPaid}
                        <span>Transaction ID: {row.upi_transactionId}</span>
                      </Typography>
                      {/* <a href={row.upi_screenshot} target="_blank" rel="noopener noreferrer">
                        <img src={row.upi_screenshot} alt="UPI Payment Screenshot"  />
                      </a> */}
                      <DownloadLink href={row.upi_screenshot} download target="_blank" rel="noopener noreferrer">
                      UPI Screenshot
                            </DownloadLink>
                      </div>
                      <TextField
                        type="datetime-local"
                        value={inputValues[row.id]?.upi_approvalDate || ''}
                        onChange={(e) => handleInputChange(row.id, 'upi', 'approvalDate', e.target.value)}
                        style={{ marginBottom: '8px', width: '100%' }}
                      />
                      <TextField
                        label="UPI Comment"
                        value={inputValues[row.id]?.upi_comment || ''}
                        onChange={(e) => handleInputChange(row.id, 'upi', 'comment', e.target.value)}
                        style={{ marginBottom: '8px', width: '100%' }}
                      />
                      <div style={{ display: 'flex', gap: '0.5rem' }}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleApproveReject(row.id, 'upi', 'approve')}
                
                        >
                          Approve UPI
                        </Button>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => handleApproveReject(row.id, 'upi', 'reject')}
                         
                        >
                          Reject UPI
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </TableCell>
            </TableRow>
          </React.Fragment>
        ))}
      </TableBody>
    </Table>
    </TableContainer>

              <div className="d-flex">
              
                <div>
                  <Button
                    onClick={() => {
                      setOpenApproveBlockForm(false);
                      setValidatedBlockApproveForm(false);
                      setCloseFunction()
                    }}
                    style={{
                      width: "9rem",
                      height: "2.3rem",
                      backgroundColor: "gray",
                      color: "white",
                    }}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </DialogContent>
      </Dialog>

      {/*Blocked Reject Form*/}

      <Dialog
        style={{ borderTop: "12px solid rgb(126, 110, 228)" }}
        maxWidth="xl"
        open={openRejectForm}
        onClose={handleCloseReject}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <div style={{ width: "37rem" }}>
            <Form
              noValidate
              validated={Validatedtabcb}
              onSubmit={formValidationReject}
            >
              <div className="mb-4 mt-1">
                <Typography
                  style={{
                    textAlign: "center",
                    fontWeight: "500",
                    color: "rgb(126, 110, 228)",
                    fontSize: 21,
                  }}
                >
                  Blocked Reject
                </Typography>
              </div>
              <Row className="mb-3">
                <Form.Group as={Col} md="6" controlId="validationCustom01">
                  <Typography style={{ color: "gray", fontSize: 13 }}>
                    Date of Reject *
                  </Typography>
                  <Form.Control
                    required
                    onChange={(e) => setDataForReject(e.target.value)}
                    type="datetime-local"
                    style={{ padding: "0.42rem", marginBottom: 14 }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please Enter Valid Name
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="12" controlId="validationCustom01">
                  <Typography style={{ color: "gray", fontSize: 13 }}>
                    Comment Box
                  </Typography>
                  <Form.Control
                    re
                    onChange={(e) => setCommentForReject(e.target.value)}
                    required
                    as="textarea"
                    style={{ padding: "0.42rem", marginBottom: 14 }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please Enter Valid Comment
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <div className="d-flex">
                <div>
                  <Button
                    type="submit"
                    style={{
                      width: "9rem",
                      height: "2.3rem",
                      backgroundColor: "rgb(126, 110, 228)",
                      color: "white",
                      marginRight: 3,
                    }}
                  >
                    Submit
                  </Button>
                </div>
                <div>
                  <Button
                    onClick={() => {
                      setOpenReject(false);
                      setValidatedtabcb(false);
                    }}
                    style={{
                      width: "9rem",
                      height: "2.3rem",
                      backgroundColor: "gray",
                      color: "white",
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </DialogContent>
      </Dialog>

      {/* Booking Approve From*/}

      {/* <Dialog
        style={{ borderTop: "12px solid rgb(126, 110, 228)" }}
        maxWidth="xl"
        open={openApproveBookingForm}
        onClose={handleCloseApproveBookingForm}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <div style={{ width: "37rem" }}>
            <Form
              noValidate
              validated={ValidatedtaBookingForm}
              onSubmit={formValidationApproveBookingForm}
            >
              <div className="mb-4 mt-1">
                <Typography
                  style={{
                    textAlign: "center",
                    fontWeight: "500",
                    color: "rgb(126, 110, 228)",
                    fontSize: 21,
                  }}
                >
                  Booking Approve
                </Typography>
              </div>

              {error && (
            <Typography color="error" style={{ marginBottom: '1rem' }}>
              {error}
            </Typography>
          )}

          {loading && <CircularProgress style={{ display: 'block', margin: '0 auto' }} />}

            
              {filteredItemsBook.map((item, index) => (
            <div key={index} style={{ marginBottom: '2rem' }}>
              <Typography variant="h6" style={{ marginBottom: '1rem' }}>
                Customer Name: {item.customerName || "N/A"}
              </Typography>

              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Pay Source</TableCell>
                      <TableCell>Transaction ID</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Amount</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(item.payments || []).map((payment, paymentIndex) => (
                      <TableRow key={paymentIndex}>
                        <TableCell>{payment.paySource || "N/A"}</TableCell>
                        <TableCell>{payment.transactionId || "N/A"}</TableCell>
                        <TableCell>{payment.createdDate ? new Date(payment.createdDate).toLocaleDateString() : "N/A"}</TableCell>
                        <TableCell>{payment.finalAmount || "N/A"}</TableCell>
                        <TableCell className="d-flex flex-row align-items-center">
                          <Button
                            variant="contained"
                            color="primary"
                            style={{ marginRight: '0.5rem' }}
                            onClick={() => handleApproval(index, paymentIndex)}
                          >
                            Approve
                          </Button>
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => handleRejection(index, paymentIndex)}
                          >
                            Reject
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              </div>
))}


             
                <div>
                  <Button
                    onClick={() => {
                      setApproveBookingForm(false);
                      setValidatedBookingForm(false);
                    }}
                    style={{
                      width: "9rem",
                      height: "2.3rem",
                      backgroundColor: "gray",
                      color: "white",
                    }}
                  >
                    Cancel
                  </Button>
                </div>
           
       
            </Form>
            </div>
       
        </DialogContent>
      </Dialog> */}

<Dialog
  style={{ borderTop: "12px solid rgb(126, 110, 228)" }}
  maxWidth="xl"
  open={openApproveBookingForm}
  onClose={handleCloseApproveBookingForm}
  aria-labelledby="alert-dialog-title"
  aria-describedby="alert-dialog-description"
>
  <DialogContent>
    <div style={{ width: "45rem" }}>
      <Form
        noValidate
        validated={ValidatedtaBookingForm}
        onSubmit={formValidationApproveBookingForm}
      >
        <div className="mb-4 mt-1">
          <Typography
            style={{
              textAlign: "center",
              fontWeight: "500",
              color: "rgb(126, 110, 228)",
              fontSize: 21,
            }}
          >
            Booking Approve
          </Typography>
        </div>

        {error && (
          <Typography color="error" style={{ marginBottom: '1rem' }}>
            {error}
          </Typography>
        )}

        {/* {loading && <CircularProgress style={{ display: 'block', margin: '0 auto' }} />}  flatNo*/}

        {filteredItemsBook
        .filter(item => item.payments.some(payment => payment.paidStatus !== 'Approved'))
        .map((item, index) => (
          <div key={index} style={{ marginBottom: '2rem' }}>
            <div className="d-flex flex-row">
            <Typography variant="h6" style={{ marginBottom: '1rem' }}>
              Customer Name: {item.customerName || "N/A"}
            </Typography>
            <Typography variant="h6" style={{ marginBottom: '1rem',marginLeft:'1rem' }}>
              flatNo: {item.flatNo || "N/A"}
            </Typography>
            </div>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Pay Source</TableCell>
                    <TableCell>Transaction ID</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>Screenshot</TableCell>
                    {/* <TableCell>Action</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(item.payments || [])
                    .filter(payment => payment.paidStatus !== 'Approved')
                    .map((payment, paymentIndex) => (
                      <TableRow key={paymentIndex}>
                        <TableCell>{payment.paySource || "N/A"}</TableCell>
                        <TableCell>
                          {payment.paySource === 'Cheque' ? payment.cheqNo || "N/A" : payment.transactionId || "N/A"}
                        </TableCell>
                        <TableCell>{payment.createdDate ? new Date(payment.createdDate).toLocaleDateString() : "N/A"}</TableCell>
                        <TableCell>{payment.finalAmount || "N/A"}</TableCell>
                
                        <TableCell className="d-flex flex-row align-items-center">
                          {/* Display image download links if available */}
                          {payment.bank_screenshot && (
                            <DownloadLink href={payment.bank_screenshot} download target="_blank" rel="noopener noreferrer">
                           Bank Screenshot
                            </DownloadLink>
                          )}
                          {payment.cheque_image && (
                            <DownloadLink href={payment.cheque_image} download target="_blank" rel="noopener noreferrer">
                       Cheque Image
                            </DownloadLink>  
                          )}
                   
           
                          <Button
                            variant="contained"
                            color="primary"
                            style={{ marginRight: '0.5rem' }}
                            onClick={() => handleApproval(index, paymentIndex)}
                          >
                            Approve
                          </Button>
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => handleRejection(index, paymentIndex)}
                          >
                            Reject
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ))}

        <div>
          <Button
            onClick={() => {
              setApproveBookingForm(false);
              setValidatedBookingForm(false);
            }}
            style={{
              width: "9rem",
              height: "2.3rem",
              backgroundColor: "gray",
              color: "white",
            }}
          >
            Cancel
          </Button>
        </div>
      </Form>
    </div>
  </DialogContent>
</Dialog>


      {/*Booking Reject From*/}

      <Dialog
        style={{ borderTop: "12px solid rgb(126, 110, 228)" }}
        maxWidth="xl"
        open={openBookRejectForm}
        onClose={handleCloseBookReject}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <div style={{ width: "37rem" }}>
            <Form
              noValidate
              validated={Validatedtabcb}
              onSubmit={formValidationRejectBook}
            >
              <div className="mb-4 mt-1">
                <Typography
                  style={{
                    textAlign: "center",
                    fontWeight: "500",
                    color: "rgb(126, 110, 228)",
                    fontSize: 21,
                  }}
                >
                  Booking Reject
                </Typography>
              </div>
              <Row className="mb-3">
                <Form.Group as={Col} md="6" controlId="validationCustom01">
                  <Typography style={{ color: "gray", fontSize: 13 }}>
                    Date of Reject *
                  </Typography>
                  <Form.Control
                    required
                    onChange={(e) => setDataForBookingReject(e.target.value)}
                    type="datetime-local"
                    style={{ padding: "0.42rem", marginBottom: 14 }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please Enter Valid Name
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="12" controlId="validationCustom01">
                  <Typography style={{ color: "gray", fontSize: 13 }}>
                    Comment Box
                  </Typography>
                  <Form.Control
                    re
                    onChange={(e) => setCommentForBookingReject(e.target.value)}
                    required
                    as="textarea"
                    style={{ padding: "0.42rem", marginBottom: 14 }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please Enter Valid Permanent Address
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <div className="d-flex">
                <div>
                  <Button
                    type="submit"
                    style={{
                      width: "9rem",
                      height: "2.3rem",
                      backgroundColor: "rgb(126, 110, 228)",
                      color: "white",
                      marginRight: 3,
                    }}
                  >
                    Submit
                  </Button>
                </div>
                <div>
                  <Button
                    onClick={() => {
                      setOpenBookRejectForm(false);
                      setValidatedtabcb(false);
                    }}
                    style={{
                      width: "9rem",
                      height: "2.3rem",
                      backgroundColor: "gray",
                      color: "white",
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PreSales;



// {filteredItems.map((row) => (
//   <TableRow key={row.id}>
//     <TableCell>{row.name}</TableCell>
//     <TableCell style={{ fontSize: 12 }}>
//       <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        
//         {/* Bank Payment */}
//         {row.bank_amtPaid && (
//           <div style={{ border: '1px solid #ddd', padding: '1rem', borderRadius: '4px' }}>
//             <Typography variant="body1">Bank: ₹{row.bank_amtPaid}</Typography>
//             <TextField
//               type="datetime-local"
//               label="Bank Approval Date"
//               value={inputValues[row.id]?.bank_approvalDate || ''}
//               onChange={(e) => handleInputChange(row.id, 'bank', 'approvalDate', e.target.value)}
//               style={{ marginBottom: 8 }}
//             />
//             <TextField
//               label="Bank Comment"
//               value={inputValues[row.id]?.bank_comment || ''}
//               onChange={(e) => handleInputChange(row.id, 'bank', 'comment', e.target.value)}
//               style={{ marginBottom: 8 }}
//             />
//             <div style={{ display: 'flex', gap: '0.5rem' }}>
//               <Button
//                 variant="contained"
//                 color="primary"
//                 onClick={() => handleApproveReject(row.id, 'bank', 'approve')}
//               >
//                 Approve Bank
//               </Button>
//               <Button
//                 variant="contained"
//                 color="secondary"
//                 onClick={() => handleApproveReject(row.id, 'bank', 'reject')}
//               >
//                 Reject Bank
//               </Button>
//             </div>
//           </div>
//         )}

//         {/* Cheque Payment */}
//         {row.cheque_amtPaid && (
//           <div style={{ border: '1px solid #ddd', padding: '1rem', borderRadius: '4px' }}>
//             <Typography variant="body1">Cheque: ₹{row.cheque_amtPaid}</Typography>
//             <TextField
//               type="datetime-local"
//               label="Cheque Approval Date"
//               value={inputValues[row.id]?.cheque_approvalDate || ''}
//               onChange={(e) => handleInputChange(row.id, 'cheque', 'approvalDate', e.target.value)}
//               style={{ marginBottom: 8 }}
//             />
//             <TextField
//               label="Cheque Comment"
//               value={inputValues[row.id]?.cheque_comment || ''}
//               onChange={(e) => handleInputChange(row.id, 'cheque', 'comment', e.target.value)}
//               style={{ marginBottom: 8 }}
//             />
//             <div style={{ display: 'flex', gap: '0.5rem' }}>
//               <Button
//                 variant="contained"
//                 color="primary"
//                 onClick={() => handleApproveReject(row.id, 'cheque', 'approve')}
//               >
//                 Approve Cheque
//               </Button>
//               <Button
//                 variant="contained"
//                 color="secondary"
//                 onClick={() => handleApproveReject(row.id, 'cheque', 'reject')}
//               >
//                 Reject Cheque
//               </Button>
//             </div>
//           </div>
//         )}

//         {/* Online Payment */}
//         {row.online_amtPaid && (
//           <div style={{ border: '1px solid #ddd', padding: '1rem', borderRadius: '4px' }}>
//             <Typography variant="body1">Online: ₹{row.online_amtPaid}</Typography>
//             <TextField
//               type="datetime-local"
//               label="Online Approval Date"
//               value={inputValues[row.id]?.online_approvalDate || ''}
//               onChange={(e) => handleInputChange(row.id, 'online', 'approvalDate', e.target.value)}
//               style={{ marginBottom: 8 }}
//             />
//             <TextField
//               label="Online Comment"
//               value={inputValues[row.id]?.online_comment || ''}
//               onChange={(e) => handleInputChange(row.id, 'online', 'comment', e.target.value)}
//               style={{ marginBottom: 8 }}
//             />
//             <div style={{ display: 'flex', gap: '0.5rem' }}>
//               <Button
//                 variant="contained"
//                 color="primary"
//                 onClick={() => handleApproveReject(row.id, 'online', 'approve')}
//               >
//                 Approve Online
//               </Button>
//               <Button
//                 variant="contained"
//                 color="secondary"
//                 onClick={() => handleApproveReject(row.id, 'online', 'reject')}
//               >
//                 Reject Online
//               </Button>
//             </div>
//           </div>
//         )}

//         {/* UPI Payment */}
//         {row.upi_amtPaid && (
//           <div style={{ border: '1px solid #ddd', padding: '1rem', borderRadius: '4px' }}>
//             <Typography variant="body1">UPI: ₹{row.upi_amtPaid}</Typography>
//             <TextField
//               type="datetime-local"
//               label="UPI Approval Date"
//               value={inputValues[row.id]?.upi_approvalDate || ''}
//               onChange={(e) => handleInputChange(row.id, 'upi', 'approvalDate', e.target.value)}
//               style={{ marginBottom: 8 }}
//             />
//             <TextField
//               label="UPI Comment"
//               value={inputValues[row.id]?.upi_comment || ''}
//               onChange={(e) => handleInputChange(row.id, 'upi', 'comment', e.target.value)}
//               style={{ marginBottom: 8 }}
//             />
//             <div style={{ display: 'flex', gap: '0.5rem' }}>
//               <Button
//                 variant="contained"
//                 color="primary"
//                 onClick={() => handleApproveReject(row.id, 'upi', 'approve')}
//               >
//                 Approve UPI
//               </Button>
//               <Button
//                 variant="contained"
//                 color="secondary"
//                 onClick={() => handleApproveReject(row.id, 'upi', 'reject')}
//               >
//                 Reject UPI
//               </Button>
//             </div>
//           </div>
//         )}

//       </div>
//     </TableCell>
//     <TableCell>
//       {/* Optional additional actions */}
//     </TableCell>
//   </TableRow>
//))}



{/* <TabPanel value="1">
<div style={{ width: "58rem" }}>
  <Typography
    style={{
      color: "rgba(41, 16, 105, 1)",
      marginBottom: 3,
      marginLeft: 15.5,
      fontSize: 18,
      color: "gray",
      fontWeight: "500",
      textAlign: "center",
    }}
  >
    Blocked
  </Typography>
  <div style={{ fontWeight: "bold" }}>
    Total Blocked: {customerRessib .filter(item => {
// Exclude items where overall_status is 'Approved'
if (item.overall_status === 'Approved') {
return false;
}

// Check if any payment amount is provided
const hasPayment = 
item.bank_amtPaid ||
item.cheque_amtPaid ||
item.online_amtPaid ||
item.upi_amtPaid;

// Check if any payment method has been approved
const hasApprovedPayment = 
(item.bank_status === 'Approved' && item.bank_amtPaid) ||
(item.cheque_status === 'Approved' && item.cheque_amtPaid) ||
(item.online_status === 'Approved' && item.online_amtPaid) ||
(item.upi_status === 'Approved' && item.upi_amtPaid);

// Exclude items where all payment amounts are missing or all payments are approved
return hasPayment && !hasApprovedPayment;
}).length}
  </div>

  <Table style={{ position: "relative", top: "1.3rem" }}>
    <TableHead sx={{ borderTop: "1px solid lightgray" }}>
      <TableRow>
        <TableCell
          align={"start"}
          style={{
            minWidth: 0,
            fontSize: 14,
            fontWeight: "600",
          }}
        >
          Name
        </TableCell>
        <TableCell
          align={"left"}
          style={{ top: 57, fontWeight: "600" }}
        >
          Mobile
        </TableCell>

        <TableCell
          align={"left"}
          style={{ top: 21, fontWeight: "600" }}
        >
          Aadhar
        </TableCell>

        <TableCell
          align={"left"}
          style={{ top: 21, fontWeight: "600" }}
        >
          Address
        </TableCell>

        <TableCell
          align={"center"}
          style={{ top: 21, fontWeight: "600", width: 18 }}
        >
          Action
        </TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
{customerRessib &&
customerRessib
.filter(item => {
// Exclude items where overall_status is 'Approved'
if (item.overall_status === 'Approved') {
return false;
}

// Check if any payment amount is provided
const hasPayment = 
item.bank_amtPaid ||
item.cheque_amtPaid ||
item.online_amtPaid ||
item.upi_amtPaid;

// Check if any payment method has been approved
const hasApprovedPayment = 
(item.bank_status === 'Approved' && item.bank_amtPaid) ||
(item.cheque_status === 'Approved' && item.cheque_amtPaid) ||
(item.online_status === 'Approved' && item.online_amtPaid) ||
(item.upi_status === 'Approved' && item.upi_amtPaid);

// Exclude items where all payment amounts are missing or all payments are approved
return hasPayment && !hasApprovedPayment;
})
.map(item => (
<TableRow
hover
role="checkbox"
tabIndex={-1}
key={item.id}  // Changed from item.Id to item.id for consistency with the provided data
>
<TableCell
align="start"
style={{ top: 57, fontSize: 12 }}
>
{item.name}
</TableCell>
<TableCell
align="start"
style={{ top: 57, fontSize: 12 }}
>
<button
style={{
fontSize: 10.7,
border: "2px solid rgb(126, 110, 228)",
backgroundColor: "white",
borderRadius: "1.2rem",
cursor: "auto",
}}
>
<FaMobileAlt
style={{
marginRight: 3,
color: "rgb(126, 110, 228)",
marginTop: "-0.16rem",
fontSize: 9,
}}
/>
{item.mobile}
</button>
</TableCell>

<TableCell
align="start"
style={{ top: 57, fontSize: 12 }}
>
{item.aadharNo}
</TableCell>

<TableCell
align="start"
style={{ top: 57, fontSize: 12 }}
>
{item.permanentAddress}
</TableCell>
<TableCell
align="start"
style={{
top: 57,
fontSize: 12,
color: "rgb(126, 110, 228) ",
}}
>
<div style={{ display: "flex" }}>
<Badge
bg="success"
style={{ cursor: "pointer" }}
onClick={() => {
handleClickOpenApproveBlock();
handleRowClickItem(item.name);
setId(item.id); // Changed from item.Id to item.id for consistency with the provided data
}}
>
Approve
</Badge>
</div>
</TableCell>
</TableRow>
))}
</TableBody>


  </Table>

  {/* <div style={{ display: 'flex', justifyContent: 'flex-end', height: 56, marginTop: 16 }}>
                            <Pagination count={Math.ceil(data.length / page)} page={cpage} onChange={pagehandle} color="secondary" sx={{ "& .Mui-selected": { backgroundColor: "blue" } }} />
                        </div> */}
// </div>
// </TabPanel> */}


{/* <TableBody>
{customerRes && 
  customerRes.filter(item => item.status !== 'approved').map((item) => {
    // Calculate total amount paid
    const totalAmountPaid = item.payments.reduce((sum, payment) => sum + parseFloat(payment.finalAmount || 0), 0);

    return (
      <React.Fragment key={item.id}>
        <TableRow hover role="checkbox" tabIndex={-1} onClick={() => handleRowClick(item.id)}>
          <TableCell align="start" style={{ top: 57, fontSize: 12 }}>
            {item.customerName}
          </TableCell>
          <TableCell align="start" style={{ top: 57, fontSize: 12 }}>
            <button
              style={{
                fontSize: 10.7,
                border: "2px solid rgb(126, 110, 228)",
                backgroundColor: "white",
                borderRadius: "1.2rem",
                cursor: "auto",
              }}
            >
              <FaMobileAlt
                style={{
                  marginRight: 3,
                  color: "rgb(126, 110, 228)",
                  marginTop: "-0.16rem",
                  fontSize: 9,
                }}
              />
              {item.mobileNumber}
            </button>
          </TableCell>
          <TableCell align="start" style={{ top: 57, fontSize: 12 }}>
            {item.loan}
          </TableCell>
          <TableCell align="start" style={{ top: 57, fontSize: 12 }}>
            {totalAmountPaid}
          </TableCell>
          <TableCell align="start" style={{ top: 57, fontSize: 12 }}>
            {item.payments.length > 0 ? 'Show Details' : 'No Payments'}
          </TableCell>
          <TableCell align="start" style={{ top: 57, fontSize: 12 }}>
            <div style={{ display: "flex" }}>
              <Badge
                bg="success"
                style={{ cursor: "pointer" }}
                onClick={() => { handleClickBookingApprove(); setId(item.id),handleRowClickItemBook(item.customerName)}}
              >
                Approve
              </Badge>
             
            </div>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell colSpan={6} style={{ paddingBottom: 0, paddingTop: 0 }}>
            <Collapse in={openRows[item.id]}>
              <Box margin={1} style={{ maxHeight: '200px', overflowY: 'auto' }}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Pay Source</TableCell>
                      <TableCell>Amount</TableCell>
                      <TableCell>Transaction ID</TableCell>
                      <TableCell>Details</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {item.payments.map((payment) => (
                      <TableRow key={payment.id}>
                        <TableCell>{payment.paySource}</TableCell>
                        <TableCell>{payment.finalAmount}</TableCell>
                        <TableCell>{payment.transactionId}</TableCell>
                        <TableCell>{payment.transferDetail || payment.cheqNo}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  })
}
</TableBody> */}