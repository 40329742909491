import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Box, Button, TableBody, Typography, colors } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import TablePagination from "@mui/material/TablePagination";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useState } from "react";
import axios from "axios";
import MuiAlert from "@material-ui/lab/Alert";
import CustomerDetailsModal from "./CustomerDetailsModel";
import {
  FaCheck,
  FaEdit,
  FaEye,
  FaMobileAlt,
  FaPhone,
  FaTrash,
  FaUserCircle,
  FaWindowClose,
} from "react-icons/fa";
// import dummyAadhar from "../images/dummyAadhar.png";
// import dummypan from "../images/dummypan.png";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Tab from "@mui/material/Tab";
import { Badge } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import ReactToPrint from "react-to-print";

function CustomerSales() {
  const [leadData, setLeadData] = useState([]);

  const [pname, setPName] = useState("");
  const [Pemail, setPEmail] = useState("");
  const [pmobile, setPMobile] = useState("");
  const [alternatemob, setAlternateMob] = useState("");
  const [aadharnumber, setAadharNumber] = useState("");
  const [pannumber, setPanNumber] = useState("");
  const [address, setAddress] = useState("");
  const [currentaddress, setCurrentAddress] = useState("");
  const [tower, setTower] = useState("");
  const [floor, setFloor] = useState("");
  const [flatno, setFlatNo] = useState("");
  const [facing, setFacing] = useState("");
  const [corner, setCorner] = useState("");
  const [amountpaid, setAmountPaid] = useState("");
  const [financeType, setFinanceType] = useState("");

  const [comment, setComment] = useState("");

  const [isVeiw, setIsVeiw] = useState();
  const [response, setResponse] = useState();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open2 = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event);
  };

  const handleClose2 = () => {
    setAnchorEl(null);
  };
  const [open, setOpen] = useState(false);

  const [Validated, setValidated] = useState(false);

  const [open1, setOpen1] = useState(false);

  const formValidation = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      add_basic();
    }

    setValidated(true);
  };

  const [Validatedb, setValidatedb] = useState(false);

  const formValidationb = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      blocktabsubmit();
    }

    setValidatedb(true);
  };

  const [Validatedt, setValidatedt] = useState(false);

  const formValidationt = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      towertabsubmit();
    }

    setValidatedt(true);
  };

  const [Validatedf, setValidatedf] = useState(false);

  const formValidationf = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      add_floortsubmit();
    }

    setValidatedf(true);
  };
  const [Validatedum, setValidatedum] = useState(false);

  const formValidationum = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      add_um();
    }

    setValidatedum(true);
  };

  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [subValue, setSubValue] = React.useState("1");
  const handleSubChange = (event, newSubValue) => {
    setSubValue(newSubValue);
  };

  const [customerRes, setCustomerResponse] = React.useState([]);

//   https://buildfastvapi.emedha.in/create-customer/api/v1/customers

  const customerObj = () => {
    axios
      .get("https://buildfastvapi.emedha.in/create-customer/api/v1/customers")
      .then((res) => {
        setCustomerResponse(res.data);
        console.log(customerRes);
      });
  };

  const [customerCrm, setCustomersCrm] = useState([]);

//   https://buildfastvapi.emedha.in/create-customer/api/v1/customers-crm

  const crmData = () => {
    axios
      .get(
        "https://buildfastvapi.emedha.in/create-customer/api/v1/customers-crm"
      )
      .then((response) => {
        setCustomersCrm(response.data);
        console.log(customerCrm);
      });
  };



// Log updated customerCrm state when it changes
React.useEffect(() => {
  crmData();
  console.log(customerCrm);
}, [customerCrm]);

// const [loading, setLoading] = useState(true);
// const [error, setError] = useState(null);


//   // Function to fetch CRM data
//   React.useEffect(() => {
//     // Function to fetch CRM data
//     const crmData = async () => {
//       try {
//         const response = await axios.get(
//           "https://buildfastvapi.emedha.in/create-customer/api/v1/customers-crm"
//         );
//         setCustomersCrm(response.data);
//       } catch (error) {
//         console.error("Error fetching CRM data:", error);
//         setError(error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     // Fetch data on mount
//     crmData();
//   }, []); // Empty dependency array means this runs once on component mount

//   React.useEffect(() => {
//     // Log updated customerCrm state when it changes
//     console.log("Updated customerCrm:", customerCrm);
//   }, [customerCrm]); // This runs whenever customerCrm changes

  // Log updated customerCrm state when it changes

  const [Project, setProject] = useState("");

  const [ProjectName, setProjectName] = useState([]);

  const getProjectNames = () => {
    axios
      .get("https://buildfastvapi.emedha.in/project/api/v1/get-basic")
      .then((res) => {
        setProjectName(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // `https://buildfastvapi.emedha.in/create-customer/api/v1/customers/${Project}

  const [customerCrms, setCustomersCrms] = useState([]);

  const searchProjectWiseCustomers = () => {
    console.log(`Searching for project: ${Project}`); // Log the project name

    axios
      .get(
        `https://buildfastvapi.emedha.in/create-customer/api/v1/customers/${Project}`
      )
      .then((res) => {
        console.log(`Received data:`, res.data);
        setCustomersCrms(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  React.useEffect(() => {
    customerObj();
 
    getProjectNames();
    searchProjectWiseCustomers();
    searchCustomerByUcinForDoucments();
    // fetchCustomerData();
  }, [Project]);
  

  const [email, setEmail] = useState("");

  const [id, setId] = useState();

  const [approvedItems, setApprovedItems] = useState([]);
  const [isActiveBtn,setActiveBtn]=useState(false)


const userTypeHead=sessionStorage.getItem('userType')
 const crmheadData=sessionStorage.getItem('user_name')
 console.log(crmheadData)


 const [userSettings, setUserSettings] = useState(null); // State to hold user settings
 const [error, setError] = useState(null); 
 
console.log(userSettings)

 
 React.useEffect(() => {
   const fetchUserSettings = async () => {
     if (!userTypeHead || !crmheadData) {
       console.error('User type or username is missing');
       return;
     }

     try {
       const response = await axios.get(
        `https://buildfastvapi.emedha.in/create-customer/api/v1/user-settings?userType=${userTypeHead}&email=${crmheadData}`
       );

       // Store the response data in state
       setUserSettings(response.data);
     } catch (error) {
       console.error('Error fetching user settings:', error);
       setError(error.response ? error.response.data : error.message);
     }
   };

   fetchUserSettings();
 }, [userTypeHead, crmheadData]); // Dependencies array

  // buildfastapi.emedha.in/create-customer/api/v1/approve-customer
  // https://buildfastvapi.emedha.in/create-customer/api/v1/approve-
  // const [ucin, setUcin] = useState(null);

  // const handleApprove = async (item) => {
  //   try {
  //     const res = await axios.post(
  //       "http://localhost:3003/approve-customer",
  //       {
  //         id: item.id,
  //         ProjectName: item.ProjectName,
  //         Tower: item.Tower,
  //         flatNo: item.flatNo,
  //         customerName: item.customerName,
  //         coapplicantName:item.coapplicantName,
  //         mobileNumber: item.mobileNumber,
  //         panNo: item.panNo,
  //         address: item.addres,
  //         email: item.email, // Assuming this is the correct key for address
  //         aadharNo: item.aadharNo,
  //         aadharImg: item.aadharImg,
  //         panImg: item.panImg,
  //         loan: item.loan,
  //         floor: item.floor,
  //         crmExecutive:item.crmExecutive,
  //         totalValue:item.totalValue
  //       }
  //     );
  //     setActiveBtn(true)

  //     setCustomerResponse((prevCustomerRes) =>
  //       prevCustomerRes.filter((customer) => customer.id !== item.id)
  //     );
  //     setApprovedItems([...approvedItems, item]);

  //     setUcin(res.data.ucin);
  //     alert(`UCIN number generated successfully`);

  //   } catch (error) {
  //     console.error(
  //       "Error processing approval:",
  //       error.response ? error.response.data : error.message
  //     );
  //     alert(
  //       "Error processing approval:",
  //       error.response ? error.response.data : error.message
  //     );
  //   }
  // };

  const [ucin, setUcin] = useState(null);
  const projectType = sessionStorage.getItem('project_type');
  // const companyId=sessionStorage.getItem("companyId");

// const handleApprove = async (item) => {
//   try {
//     // First API call: Approving customer
//     // https://buildfastvapi.emedha.in/create-customer/api/v1/approve-customer-villa

//     const res = await axios.post(
//       "https://buildfastvapi.emedha.in/create-customer/api/v1/approve-customer-villa",
//       {
//         id: item.id,
//         ProjectName: item.ProjectName,
//         Tower: item.Tower,
//         flatNo: item.flatNo,
//         customerName: item.customerName,
//         coapplicantName: item.coapplicantName,
//         mobileNumber: item.mobileNumber,
//         panNo: item.panNo,
//         address: item.addres,
//         email: item.email, // Assuming this is the correct key for address
//         aadharNo: item.aadharNo,
//         aadharImg: item.aadharImg,
//         panImg: item.panImg,
//         loan: item.loan,
//         floor: item.floor,
//         crmExecutive: item.crmExecutive,
//         totalValue: item.totalValue
//       }
//     );

//     // Set UCIN from response
//     setUcin(res.data.ucin);
//     alert(`UCIN number generated successfully`);

//     // // Call the second API only if the first one was successful
//     // const secondApiUrl = `https://emedha.com/kolla/sendluxucin.php?name=${item.customerName}&mobile=91${item.mobileNumber}&param1=${res.data.ucin}&param2=${item.flatNo}&param3=${userSettings.UserName}&param4=${userSettings.Mobile}&param5=${userSettings.Email}`;

//     // // Second API call: Sending UCIN data
//     // const secondRes = await axios.get(secondApiUrl);
//     // console.log("Second API Response:", secondRes.data);

//     // // Handle successful second API response if needed
//     // alert("UCIN sent successfully to the external system");

//     // Update state for UI changes
//     setActiveBtn(true);
//     setCustomerResponse((prevCustomerRes) =>
//       prevCustomerRes.filter((customer) => customer.id !== item.id)
//     );
//     setApprovedItems([...approvedItems, item]);

//   } catch (error) {
//     console.error(
//       "Error processing approval:",
//       error.response ? error.response.data : error.message
//     );
//     alert(
//       "Error processing approval:",
//       error.response ? error.response.data : error.message
//     );
//   }
// };


const handleApprove = async (item) => {
  try {
    // Get the projectType from sessionStorage
    const projectType = sessionStorage.getItem('project_type');
    
    // Set the API URL based on projectType
    let apiUrl;
    if (projectType === 'Apartment') {
      apiUrl = "https://buildfastvapi.emedha.in/create-customer/api/v1/approve-customer";
    } else {
      apiUrl = "https://buildfastvapi.emedha.in/create-customer/api/v1/approve-customer-villa";
    }

    // First API call: Approving customer based on projectType
    const res = await axios.post(
      apiUrl,
      {
        id: item.id,
        ProjectName: item.ProjectName,
        Tower: item.Tower,
        flatNo: item.flatNo,
        customerName: item.customerName,
        coapplicantName: item.coapplicantName,
        mobileNumber: item.mobileNumber,
        panNo: item.panNo,
        address: item.addres,
        email: item.email, // Assuming this is the correct key for address
        aadharNo: item.aadharNo,
        aadharImg: item.aadharImg,
        panImg: item.panImg,
        loan: item.loan,
        floor: item.floor,
        crmExecutive: item.crmExecutive,
        totalValue: item.totalValue,
        companyId:companyId
      }
    );

    // Set UCIN from response
    setUcin(res.data.ucin);
    alert(`UCIN number generated successfully`);

    // Optional: If you need to call a second API based on the first one being successful
    // const secondApiUrl = `https://emedha.com/kolla/sendluxucin.php?name=${item.customerName}&mobile=91${item.mobileNumber}&param1=${res.data.ucin}&param2=${item.flatNo}&param3=${userSettings.UserName}&param4=${userSettings.Mobile}&param5=${userSettings.Email}`;
    // const secondRes = await axios.get(secondApiUrl);
    // console.log("Second API Response:", secondRes.data);

    // // Handle successful second API response if needed
    // alert("UCIN sent successfully to the external system");

    // Update state for UI changes
    setActiveBtn(true);
    setCustomerResponse((prevCustomerRes) =>
      prevCustomerRes.filter((customer) => customer.id !== item.id)
    );
    setApprovedItems([...approvedItems, item]);

  } catch (error) {
    console.error(
      "Error processing approval:",
      error.response ? error.response.data : error.message
    );
    alert(
      "Error processing approval: ",
      error.response ? error.response.data : error.message
    );
  }
};


  const [selectedUCIN, setSelectedUCIN] = useState(null);
  const [modalShow, setModalShow] = useState(false);

  const [ValidatedUcinSeacrh, setValidatedUciSearch] = useState(false);

  const formValidationUcinSearchForDocuments = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      searchCustomerByUcinForDoucments();
    }

    setValidatedUciSearch(true);
  };

  const handleShowModal = (ucin) => {
    setSelectedUCIN(ucin);
    setModalShow(true);
  };

  const handleCloseModal = () => {
    setModalShow(false);
    setSelectedUCIN(null);
  };

  const [selectedUCINDocument, setSelectedUCINDocument] = useState(null);
  const [modalShowForDocuemnts, setModalShowForDocuments] = useState(false);

  const handleShowModalForDocuments = (ucin) => {
    setSelectedUCINDocument(ucin);
    setModalShowForDocuments(true);
  };

  const handleCloseModalForDocuments = () => {
    setModalShowForDocuments(false);
    setSelectedUCINDocument(null);
  };

  const [searchUcinForDocument, setUcinSearchOfDocument] = useState("");
  const [customerDataOfDocumentation, setCustomerDataForDocuementation] =
    useState([]);

  const [serachUcin, setUcinSearch] = useState("");
  const [customerData, setCustomerData] = useState([]);

  const searchCustomerByUcin = async () => {
    try {
      const response = await axios.get(
        `https:///buildfastvapi.emedha.in/create-customer/api/v1/api/customers?ucin=${serachUcin}`
      );
      setCustomerData(response.data);

      console.log(customerData);
    } catch (error) {
      console.error("Error searching by UCIN:", error);
      // Handle error
    }
  };

  const formValidationUcinSearch = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      searchCustomerByUcin();
    }

    setValidatedUciSearch(true);
  };

//   https://buildfastvapi.emedha.in/create-customer/api/v1/api/customers/documents?ucin=${searchUcinForDocument}

  const searchCustomerByUcinForDoucments = async () => {
    try {
      const response = await axios.get(
        `https://buildfastvapi.emedha.in/create-customer/api/v1/api/customers/documents?ucin=${searchUcinForDocument}`
      );

      setCustomerDataForDocuementation(response.data);

      console.log(customerData);
    } catch (error) {
      console.error("Error searching by UCIN:", error);
      // Handle error
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      searchCustomerByUcin();
    }
  };

  const handleKeyPressForDocument = (e) => {
    if (e.key === "Enter") {
      searchCustomerByUcinForDoucments();
    }
  };

  const [customerCancelDate, setDataForCustomerCancel] = useState("");
  const [customerCancelComment, setCommentForCustomerCancel] = useState("");
  const [blockedCustomers, setBlockedCustomers] = useState();

  const [ValidatedCustomerForm, setValidatedCustomerCancelForm] =
    useState(false);

  const [openCutomerCancelForm, setOpenCustomerCancelForm] = useState();

  const handleClickOpenCustomerCancelForm = () => {
    setOpenCustomerCancelForm(true);
  };

  const handleCloseCustomerCancelForm = () => {
    setOpenCustomerCancelForm(false);
  };


  // https://buildfastvapi.emedha.in/create-customer/api/v1/cancel-customer

  const formValidationForCustomerCancel = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      try {
        await axios.post(
          "https://buildfastvapi.emedha.in/create-customer/api/v1/cancel-customer",
          {
            id,
            cancelDate: customerCancelDate,
            comment: customerCancelComment,
          }
        );

        setDataForCustomerCancel("");
        setCommentForCustomerCancel("");
        setOpenCustomerCancelForm(false);
      } catch (error) {
        console.error("Error approving blocked customer:", error);
      }
      setValidatedCustomerCancelForm(false);
    }
  };

  // https://buildfastvapi.emedha.in/create-customer/api/v1/send-customer-email

  const componentRef = React.useRef();

  const handleAttachToMail = (item) => {
    axios
      .post(
        "https://buildfastvapi.emedha.in/create-customer/api/v1/send-customer-email",
        item
      )
      .then((response) => {
        console.log(response.data.message);
        console.log(email);
        alert('All Documents Sent to the Customer')
      })
      .catch((error) => {
        console.error("Error attaching to mail:", error);
        alert("Error attaching to mail:", error)
      });
  };

  const modalStyle = {
    zIndex: 10000, // Adjust the zIndex value as needed
  };

  const PrName = sessionStorage.getItem("ProjectName");
  console.log(PrName)

  const crmExName=sessionStorage.getItem('user_name')
  const crmType=sessionStorage.getItem('userType')
  console.log(PrName)
  console.log(crmType)
  console.log(crmExName)


  const filteredCustomers = customerRes.filter(customer => 
    !customerCrm.some(approvedCustomer => approvedCustomer.id === customer.id)
  );

  console.log(filteredCustomers)

  // https://buildfastvapi.emedha.in/create-customer/api/v1/api/generate-doc

  const sendSaleAgreementToAdmin = async (ucin) => {
    try {
      const response = await axios.post('https://buildfastvapi.emedha.in/create-customer/api/v1/api/generate-doc', { ucin});
      alert(response.data.message);
    } catch (error) {
      console.error('Error generating document:', error);
    }
  };

  // https://buildfastvapi.emedha.in/create-customer/api/v1/api/send-doc-to-customer

  const handleSendToCustomer = (ucin) => {
    axios.post('https://buildfastvapi.emedha.in/create-customer/api/v1/api/send-doc-to-customer', { ucin })
      .then(response => {
        alert(response.data.message);
      })
      .catch(error => {
        console.error('Error sending document to customer:', error);
      });
  }


  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5); // Set default rows per page

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page
  };

  const paginatedData = filteredCustomers
    .filter((item) => {
      const hasTwentyPercentReceived = item.payments.some(payment => payment.status === "20% received");

      if (sessionStorage.getItem("superAdmin")) {
        return hasTwentyPercentReceived;
      } else if (sessionStorage.getItem("userType") === "CRM Head") {
        return hasTwentyPercentReceived && item.ProjectName === PrName;
      }
      else if (sessionStorage.getItem("userType") === "Admin") {
        return hasTwentyPercentReceived && item.ProjectName === PrName;
      }
      
      else if (sessionStorage.getItem("userType") === crmType) {
        return hasTwentyPercentReceived && item.crmExecutive === crmExName;
      } else {
        return false;
      }
    })
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage); // Paginate data


    const [pageDb, setPageDb] = useState(0);
    const [rowsPerPageDb, setRowsPerPageDb] = useState(5); // Set default rows per page
  
    const handleChangePageDb = (event, newPage) => {
      setPageDb(newPage);
    };
  
    const handleChangeRowsPerPageDb = (event) => {
      setRowsPerPageDb(parseInt(event.target.value, 10));
      setPageDb(0); // Reset to the first page
    };
  
    const filteredCustomersDb = customerCrm.filter((item) => {
      if (sessionStorage.getItem("superAdmin")) {
        return true;
      } else if (sessionStorage.getItem("userType") === "CRM Head") {
        return item.ProjectName === PrName;
      } else if (sessionStorage.getItem("userType") === crmType) {
        return item.ProjectName === PrName && item.crmExecutive === crmExName;
      } else {
        return false;
      }
    });
  
    const paginatedDataDb = filteredCustomers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  

    const handleFileOpen = (url) => {
      // Create a temporary anchor element
      const link = document.createElement('a');
      link.href = url;
      link.target = '_blank'; // Open in a new tab
      link.rel = 'noopener noreferrer'; // Security improvement
      link.download = ''; // Suggests downloading the file
    
      // Append the anchor to the body
      document.body.appendChild(link);
    
      // Trigger a click on the anchor
      link.click();
    
      // Remove the anchor from the body
      document.body.removeChild(link);
    };


    const [projectName, setProjectNames] = useState([]);

    const getProjectNamess = () => {
      axios
        .get("https://buildfastvapi.emedha.in/project/api/v1/get-basic")
        .then((res) => {
          setProjectNames(res.data.data);
          console.log(projectName);
        })
        .catch((err) => {
          console.log(err);
        });
    };
  
    React.useEffect(() => {
      getProjectNamess();
    }, []);
  
    const [filteredProjects, setFilteredProjects] = useState([]);
    console.log(filteredProjects)

    React.useEffect(() => {
      const userType = sessionStorage.getItem("userType");
      const projectNames = sessionStorage.getItem("ProjectName");
      console.log(projectNames)
  
      // Filter projectNamesss based on userType and ProjectName
      const filtered = projectName.filter(project => {
        if (userType === "Admin") {
          return project.ProjectName === projectNames;
        } else if (userType === "CRM Head"){
          return project.ProjectName === projectNames;
        }
        return true; // Show all projects for non-admins
      });
  
      setFilteredProjects(filtered);
    }, [projectName]);


    const companyId=sessionStorage.getItem("companyId");



  return (
    <>
      <div>
       
       
        {/* <div>
          <Dialog
            style={{ borderTop: "12px solid rgb(126, 110, 228)" }}
            maxWidth="xl"
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogContent> */}
              <Box sx={{ width: "100%", typography: "body1" }}>
                <TabContext value={value}>
                  <Box
                    sx={{
                      borderBottom: 1,
                      borderColor: "divider",
                      color: "rgb(86, 63, 146)",
                    }}
                  >
                    <TabList
                      onChange={handleChange}
                      aria-label="lab API tabs example"
                      indicatorColor="secondary"
                    >
                      <Tab
                        label="Sale Booking Platform"
                        value="1"
                        style={{ color: "rgb(86, 63, 146)" }}
                      />
                      <Tab
                        label="Database platform"
                        value="2"
                        style={{ color: "rgb(86, 63, 146)" }}
                      />
                      <Tab
                        label="Document platform"
                        value="3"
                        style={{ color: "rgb(86, 63, 146)" }}
                      />
                      {/* <Tab
                        label="Internal communication platform"
                        value="4"
                        style={{ color: "rgb(86, 63, 146)" }}
                      /> */}
                      <Tab
                        label="Cancellation platform"
                        value="5"
                        style={{ color: "rgb(86, 63, 146)" }}
                      />
                      <Tab
                        label="Parking allotment platform"
                        value=""
                        style={{ color: "rgb(86, 63, 146)" }}
                      />
                    </TabList>
                  </Box>
                  <TabPanel value="1">
                    <div style={{ width: "58rem" }}>
                      <Typography
                        style={{
                          color: "rgba(41, 16, 105, 1)",
                          marginBottom: 3,
                          marginLeft: 15.5,
                          fontSize: 18,
                          color: "gray",
                          fontWeight: "500",
                          textAlign: "center",
                        }}
                      >
                        Customers
                      </Typography>
                      <div style={{ fontWeight: "bold",color:'black' }}>
                        Total Customers:{" "}
                        {
                           filteredCustomers
                           .filter((item) => {
                             // Check if any payment in the `payments` array has the status "20% received"
                             const hasTwentyPercentReceived = item.payments.some(payment => payment.status === "20% received");
                             const companyIdd=item.company_id === parseInt(companyId)
                     
                             if (sessionStorage.getItem("superAdmin")) {
                               return hasTwentyPercentReceived && companyIdd;
                             } else if (sessionStorage.getItem("userType") === "CRM Head") {
                               return hasTwentyPercentReceived && companyIdd && item.ProjectName === PrName;
                             } 
                             else if (sessionStorage.getItem("userType") === "Admin") {
                              return hasTwentyPercentReceived && companyIdd && item.ProjectName === PrName;
                            }
                             
                             else if (sessionStorage.getItem("userType") === crmType) {
                               return hasTwentyPercentReceived && companyIdd && item.crmExecutive === crmExName;
                             } else {
                               return false;
                             }
                           }).length
                        }
                      </div>

                      <Table style={{ position: "relative", top: "1.3rem"}}>
                        <TableHead sx={{ borderTop: "1px solid lightgray" }}>
                          <TableRow>
                            <TableCell
                              align={"start"}
                              style={{
                                minWidth: 0,
                                fontSize: 14,
                                fontWeight: "600",
                              }}
                            >
                              Name
                            </TableCell>
                            <TableCell
                              align={"left"}
                              style={{ top: 57, fontWeight: "600" }}
                            >
                              Mobile
                            </TableCell>

                            <TableCell
                              align={"left"}
                              style={{ top: 21, fontWeight: "600" }}
                            >
                              Aadhar
                            </TableCell>
                            <TableCell
                              align={"left"}
                              style={{ top: 21, fontWeight: "600" }}
                            >
                              Pan
                            </TableCell>

                            <TableCell
                              align={"left"}
                              style={{ top: 21, fontWeight: "600" }}
                            >
                              Address
                            </TableCell>

                            <TableCell
                              align={"left"}
                              style={{ top: 21, fontWeight: "600", width: 18 }}
                            >
                              Action
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
  {paginatedData.filter(( item=>item.company_id === parseInt(companyId)))
      .map((item) => (
        <TableRow
          hover
          role="checkbox"
          tabIndex={-1}
          key={item.id} // Use item.id instead of item.Id for consistency
        >
          <TableCell
            align={"start"}
            style={{ top: 57, fontSize: 12 }}
          >
            {item.customerName}
          </TableCell>
          <TableCell
            align={"start"}
            style={{ top: 57, fontSize: 12 }}
          >
            <button
              style={{
                fontSize: 10.7,
                border: "2px solid rgb(126, 110, 228)",
                backgroundColor: "white",
                borderRadius: "1.2rem",
                cursor: "auto",
              }}
            >
              <FaMobileAlt
                style={{
                  marginRight: 3,
                  color: "rgb(126, 110, 228)",
                  marginTop: "-0.16rem",
                  fontSize: 9,
                }}
              />
              {item.mobileNumber}
            </button>
          </TableCell>
          <TableCell
            align={"start"}
            style={{ top: 57, fontSize: 12 }}
          >
            {item.aadharNo}
          </TableCell>
          <TableCell
            align={"start"}
            style={{ top: 57, fontSize: 12 }}
          >
            {item.panNo}
          </TableCell>
          <TableCell
            align={"start"}
            style={{ top: 57, fontSize: 12 }}
          >
            {item.addres}
          </TableCell>
          <TableCell
            align={"start"}
            style={{
              top: 57,
              fontSize: 12,
              color: "rgb(126, 110, 228)",
            }}
          >
            <div style={{ display: "flex" }}>
              <Badge
                bg="success"
                style={{ cursor: "pointer" }}
                disabled={isActiveBtn}
                onClick={() => {
                  handleApprove(item);
                  setId(item.id);
                }}
                type="button"
              >
                Generate UCIN
              </Badge>
            </div>
          </TableCell>
        </TableRow>
      ))}
</TableBody>

                      </Table>

                     
                    </div>

                    <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredCustomers.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{ marginTop: '1rem' }} 
      />
                  </TabPanel>
       
                

                  <TabPanel value="2">
                    <div style={{ width: "58rem" ,marginBottom:'58px'}}>
                      <Typography
                        style={{
                          color: "rgba(41, 16, 105, 1)",
                          marginBottom: 3,
                          marginLeft: 15.5,
                          fontSize: 18,
                          color: "gray",
                          fontWeight: "500",
                          textAlign: "center",
                        }}
                      >
                        Customers
                      </Typography>
                      <div
                        style={{ fontWeight: "bold",color:'black'  }}
                        className="d-flex flex-row align-items-center justify-content-between"
                      >
                        Total Customers Of {Project}:   {customerCrms &&
                          
                          customerCrms.filter((item) => {
                              if (sessionStorage.getItem("superAdmin")) {
                                return item.company_id===parseInt(companyId)
                              } else if (sessionStorage.getItem("userType")==="CRM Head"){
                                return(
                          
                                item.ProjectName === PrName
                                )
                              
                              }

                              else if (sessionStorage.getItem("userType")==="Admin"){
                                return(
                          
                                item.ProjectName === PrName
                                )
                              
                              }
                              
                              
                              
                              else if (sessionStorage.getItem("userType")===crmType) {
                                return (
                                  item.ProjectName === PrName&&
                                  item.crmExecutive===crmExName
                                
                                );
                              }else{
                              return false;
                            }
                            }).length}
                        <Form
                          noValidate
                          validated={Validated}
                          onSubmit={formValidation}
                        >
                          <Row className="mb-3">
                            {/* <Form.Group
                              as={Col}
                              md="12"
                              controlId="validationCustom01"
                              style={{ width: "350px" }}
                            >
                              <Typography
                                style={{ color: "gray", fontSize: 13 }}
                              >
                                Select Project
                              </Typography>
                              <Form.Select
                                value={Project}
                                onChange={(e) => setProject(e.target.value)}
                                style={{ padding: "0.42rem", marginBottom: 14 }}
                              >
                              
                                  <option>{PrName}</option>
                           
                              </Form.Select>
                              <Form.Control.Feedback type="invalid">
                                Please Enter Valid manager
                              </Form.Control.Feedback>
                            </Form.Group> */}
                            <Form.Group
  as={Col}
  md="12"
  controlId="validationCustom01"
  style={{ width: "350px" }}
>
  <Typography style={{ color: "gray", fontSize: 13 }}>
    Select Project
  </Typography>
  <Form.Select
    value={Project} // Ensure `Project` state is defined and updated
    onChange={(e) => setProject(e.target.value)}
    style={{ padding: "0.42rem", marginBottom: 14 }}
  >
    
    <option value="" disabled>Select Project</option> {/* Placeholder option */}
    {filteredProjects.length > 0 ? (
      filteredProjects.filter((proj=>proj.company_id===parseInt(companyId))).map((proj) => (
        <option key={proj.id} value={proj.ProjectName}>
          {proj.ProjectName} {/* Adjust according to your API response structure */}
        </option>
      ))
    ) : (
      <option>Loading...</option>
    )}
  </Form.Select>
  <Form.Control.Feedback type="invalid">
    Please Select a Valid Project
  </Form.Control.Feedback>
</Form.Group>

                          </Row>
                        </Form>
                      </div>

                      <Table style={{ position: "relative", top: "1.3rem" }}>
                        <TableHead sx={{ borderTop: "1px solid lightgray" }}>
                          <TableRow>
                            <TableCell
                              align={"start"}
                              style={{
                                minWidth: 0,
                                fontSize: 14,
                                fontWeight: "600",
                              }}
                            >
                              Name
                            </TableCell>
                            <TableCell
                              align={"left"}
                              style={{ top: 57, fontWeight: "600" }}
                            >
                              Mobile
                            </TableCell>

                            <TableCell
                              align={"left"}
                              style={{ top: 21, fontWeight: "600" }}
                            >
                              Email
                            </TableCell>
                       {sessionStorage.getItem('project_type')!=="Villa" &&     <TableCell
                              align={"left"}
                              style={{ top: 21, fontWeight: "600", width: 18 }}
                            >
                              Tower
                            </TableCell> }
                            <TableCell
                              align={"left"}
                              style={{ top: 21, fontWeight: "600" }}
                            >
                              FlatNo
                            </TableCell>

                            <TableCell
                              align={"left"}
                              style={{ top: 21, fontWeight: "600" }}
                            >
                              TotalValue
                            </TableCell>
                            <TableCell
                              align={"left"}
                              style={{ top: 21, fontWeight: "600" }}
                            >
                              Finance Type
                            </TableCell>
                          
                          
                          
                            <TableCell
                              align={"left"}
                              style={{ top: 21, fontWeight: "600", width: 18 }}
                            >
                              UCIN
                            </TableCell>
{/*                             
                            <TableCell
                              align={"left"}
                              style={{ top: 21, fontWeight: "600", width: 18 }}
                            >
                              Sale Agreement
                            </TableCell>
                            <TableCell
                              align={"left"}
                              style={{ top: 21, fontWeight: "600", width: 18 }}
                            >
                             Download
                            </TableCell>*/}
                          </TableRow> 
                        </TableHead>
                        <TableBody>
                          {customerCrms &&
                          
                          customerCrms.filter((item) => {
                              if (sessionStorage.getItem("superAdmin")) {
                                return item.company_id===parseInt(companyId)
                              } else if (sessionStorage.getItem("userType")==="CRM Head"){
                                return(
                          
                                item.ProjectName === PrName
                                )
                              
                              }

                              else if (sessionStorage.getItem("userType")==="Admin"){
                                return(
                          
                                item.ProjectName === PrName
                                )
                              
                              }
                              
                              
                              
                              else if (sessionStorage.getItem("userType")===crmType) {
                                return (
                                  item.ProjectName === PrName&&
                                  item.crmExecutive===crmExName
                                
                                );
                              }else{
                              return false;
                            }
                            })
                            .map((item) => (
                              <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={item.Id}
                                style={{
                                  color:
                                    item.status === "Cancelled"
                                      ? "red"
                                      : "black",
                                }}
                              >
                                <TableCell
                                  align={"start"}
                                  style={{
                                    top: 57,
                                    fontSize: 12,
                                    color:
                                      item.status === "Cancelled"
                                        ? "red"
                                        : "black",
                                  }}
                                >
                                  {item.customerName}
                                </TableCell>
                                <TableCell
                                  align={"start"}
                                  style={{
                                    top: 57,
                                    fontSize: 12,
                                    color:
                                      item.status === "Cancelled"
                                        ? "red"
                                        : "black",
                                  }}
                                >
                                  <button
                                    style={{
                                      fontSize: 10.7,
                                      border: "2px solid rgb(126, 110, 228)",
                                      backgroundColor: "white",
                                      borderRadius: "1.2rem",
                                      cursor: "auto",
                                      width: "80px",
                                    }}
                                  >
                                    <FaMobileAlt
                                      style={{
                                        marginRight: 3,
                                        color: "rgb(126, 110, 228)",
                                        marginTop: "-0.16rem",
                                        fontSize: 9,
                                        color:
                                          item.status === "Cancelled"
                                            ? "red"
                                            : "black",
                                      }}
                                    />
                                    {item.mobileNumber}
                                  </button>
                                </TableCell>

                                <TableCell
                                  align={"start"}
                                  style={{
                                    top: 57,
                                    fontSize: 12,
                                    color:
                                      item.status === "Cancelled"
                                        ? "red"
                                        : "black",
                                  }}
                                >
                                  {item.email}
                                </TableCell>
                                {sessionStorage.getItem('project_type')!=="Villa" &&       <TableCell
                                  align={"start"}
                                  style={{
                                    top: 57,
                                    fontSize: 12,
                                    color:
                                      item.status === "Cancelled"
                                        ? "red"
                                        : "black",
                                  }}
                                >
                                  {item.Tower}
                                </TableCell>}
                                <TableCell
                                  align={"start"}
                                  style={{
                                    top: 57,
                                    fontSize: 12,
                                    color:
                                      item.status === "Cancelled"
                                        ? "red"
                                        : "black",
                                  }}
                                >
                                  {item.flatNo}
                                </TableCell>
                                <TableCell
                                  align={"start"}
                                  style={{
                                    top: 57,
                                    fontSize: 12,
                                    color:
                                      item.status === "Cancelled"
                                        ? "red"
                                        : "black",
                                  }}
                                >
                                  {item.totalValue}
                                </TableCell>
                                <TableCell
                                  align={"start"}
                                  style={{
                                    top: 57,
                                    fontSize: 12,
                                    color:
                                      item.status === "Cancelled"
                                        ? "red"
                                        : "black",
                                  }}
                                >
                                  {item.loan}
                                </TableCell>
                              

                                <TableCell
                                  align={"start"}
                                  style={{
                                    top: 57,
                                    fontSize: 12,
                                    color: "green",
                                    cursor: "pointer",
                                    color:
                                      item.status === "Cancelled"
                                        ? "red"
                                        : "black",
                                  }}
                                  onClick={() => handleShowModal(item.ucin)}
                                >
                                  {item.ucin}
                                </TableCell>
                                {/* <TableCell
                                  align={"start"}
                                  style={{
                                    display:'flex',
                                    width:280,
                                    height:90,
                                    top: 57,
                                    fontSize: 12,
                                    color: "green",
                                    cursor: "pointer",
                                    color:
                                      item.status === "Cancelled"
                                        ? "red"
                                        : "black",
                                  }}>
                            <Button style={{width:'80px'}} onClick={()=>sendSaleAgreementToAdmin(item.ucin)}>Admin</Button>
                            {item.document_status === 'Approved' && (
                    <Button
                    style={{width:'80px'}}
                      onClick={() => handleSendToCustomer(item.ucin)}
                    >
                      Customer
                    </Button>)}
                                
                                
                                </TableCell>
                                <TableCell
                                  align={"start"}
                                  style={{
                                    top: 57,
                                    fontSize: 12,
                                    color: "green",
                                    cursor: "pointer",
                                    color:
                                      item.status === "Cancelled"
                                        ? "red"
                                        : "black",
                                  }}
                                  onClick={() => handleFileOpen(item.document_path)}
                                >
                                 Download
                                </TableCell> */}
                                
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </div>
                    <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredCustomersDb.length}
          rowsPerPage={rowsPerPageDb}
          page={pageDb}
          onPageChange={handleChangePageDb}
          onRowsPerPageChange={handleChangeRowsPerPageDb}
          sx={{ marginTop: '1rem' }} 
        />
                  </TabPanel>

                

                  <TabPanel value="3">
                    <div style={{ width: "58rem" }}>
                      <Typography
                        style={{
                          color: "rgba(41, 16, 105, 1)",
                          marginBottom: 3,
                          marginLeft: 15.5,
                          fontSize: 18,
                          color: "gray",
                          fontWeight: "500",
                          textAlign: "center",
                        }}
                      >
                        Customers
                      </Typography>

                      <div>
                        <Form
                          noValidate
                          validated={ValidatedUcinSeacrh}
                          onSubmit={formValidationUcinSearchForDocuments}
                        >
                          <Typography
                            style={{
                              color: "rgba(41, 16, 105, 1)",
                              marginBottom: 3,
                              marginLeft: 13.5,
                              fontSize: 18,
                              color: "gray",
                              fontWeight: "500",
                            }}
                          >
                            Search By UCIN
                          </Typography>
                          <div className="d-flex flex-row align-items-center">
                            <input
                              // onChange={handleUcinSearch}
                              onKeyPress={handleKeyPressForDocument}
                              value={searchUcinForDocument}
                              onChange={(e) =>
                                setUcinSearchOfDocument(e.target.value)
                              }
                              style={{
                                height: "2.3rem",
                                color: "gray",
                                borderRadius: "0.3rem",
                                outline: "none",
                                width: "26rem",
                                border: "0.7px solid lightgray",
                              }}
                              placeholder="Search..."
                            />
                            <div className="d-flex flex-row align-items-center justify-content-center">
                              <button
                                type="submit"
                                style={{
                                  backgroundColor: "rgb(126, 110, 228)",
                                  width: "70px",
                                  height: "35px",
                                  color: "white",
                                  textAlign: "center",
                                  borderRadius: "0.3rem",
                                }}
                              >
                                Search
                              </button>
                            </div>
                          </div>
                        </Form>
                      </div>

                      <Table style={{ position: "relative", top: "1.3rem" }}>
                        <TableHead sx={{ borderTop: "1px solid lightgray" }}>
                          <TableRow>
                            <TableCell
                              align={"start"}
                              style={{
                                minWidth: 0,
                                fontSize: 14,
                                fontWeight: "600",
                              }}
                            >
                              Name
                            </TableCell>
                            <TableCell
                              align={"left"}
                              style={{ top: 57, fontWeight: "600" }}
                            >
                              Mobile
                            </TableCell>

                            <TableCell
                              align={"left"}
                              style={{ top: 21, fontWeight: "600" }}
                            >
                              Aadhar
                            </TableCell>
                            <TableCell
                              align={"left"}
                              style={{ top: 21, fontWeight: "600" }}
                            >
                              Pan
                            </TableCell>

                            <TableCell
                              align={"left"}
                              style={{ top: 21, fontWeight: "600" }}
                            >
                              Address
                            </TableCell>
                            <TableCell
                              align={"left"}
                              style={{ top: 21, fontWeight: "600", width: 18 }}
                            >
                              ProjectName
                            </TableCell>

                            <TableCell
                              align={"left"}
                              style={{ top: 21, fontWeight: "600", width: 18 }}
                            >
                              FlatNo
                            </TableCell>
                            <TableCell
                              align={"left"}
                              style={{ top: 21, fontWeight: "600", width: 18 }}
                            >
                              UCIN
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {customerDataOfDocumentation &&
                            customerDataOfDocumentation.map((item) => (
                              <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={item.Id}
                              >
                                <TableCell
                                  align={"start"}
                                  style={{
                                    top: 57,
                                    fontSize: 12,
                                  }}
                                >
                                  {item.customerName}
                                </TableCell>
                                <TableCell
                                  align={"start"}
                                  style={{
                                    top: 57,
                                    fontSize: 12,
                                  }}
                                >
                                  <button
                                    style={{
                                      fontSize: 10.7,
                                      border: "2px solid rgb(126, 110, 228)",
                                      backgroundColor: "white",
                                      borderRadius: "1.2rem",
                                      cursor: "auto",
                                      width: "80px",
                                    }}
                                  >
                                    <FaMobileAlt
                                      style={{
                                        marginRight: 3,
                                        color: "rgb(126, 110, 228)",
                                        marginTop: "-0.16rem",
                                        fontSize: 9,
                                      }}
                                    />
                                    {item.mobileNumber}
                                  </button>
                                </TableCell>

                                <TableCell
                                  align={"start"}
                                  style={{
                                    top: 57,
                                    fontSize: 12,
                                  }}
                                >
                                  {item.aadharNo}
                                </TableCell>
                                <TableCell
                                  align={"start"}
                                  style={{
                                    top: 57,
                                    fontSize: 12,
                                  }}
                                >
                                  {item.panNo}
                                </TableCell>
                                <TableCell
                                  align={"start"}
                                  style={{
                                    top: 57,
                                    fontSize: 12,
                                  }}
                                >
                                  {item.address}
                                </TableCell>
                                <TableCell
                                  align={"start"}
                                  style={{
                                    top: 57,
                                    fontSize: "12px",
                                  }}
                                >
                                  {item.ProjectName}
                                </TableCell>
                                <TableCell
                                  align={"start"}
                                  style={{
                                    top: 57,
                                    fontSize: 12,
                                    color: "rgb(126, 110, 228) ",
                                  }}
                                >
                                  {item.flatNo}
                                </TableCell>

                                <TableCell
                                  align={"start"}
                                  style={{
                                    top: 57,
                                    fontSize: 12,
                                    color: "green",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    handleShowModalForDocuments(item.ucin)
                                  }
                                >
                                  {item.ucin}
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </div>
                  </TabPanel>

                  <TabPanel value="5">
                    <div style={{ width: "47rem" }}>
                      <Form
                        noValidate
                        validated={ValidatedUcinSeacrh}
                        onSubmit={formValidationUcinSearch}
                      >
                        <div className="mb-4 mt-1">
                          <Typography
                            style={{
                              textAlign: "center",
                              fontWeight: "500",
                              color: "rgb(126, 110, 228)",
                              fontSize: 21,
                            }}
                          >
                            Cancellation
                          </Typography>
                        </div>
                        <div>
                          <Typography
                            style={{
                              color: "rgba(41, 16, 105, 1)",
                              marginBottom: 3,
                              marginLeft: 13.5,
                              fontSize: 18,
                              color: "gray",
                              fontWeight: "500",
                            }}
                          >
                            Search By UCIN
                          </Typography>
                          <div className="d-flex flex-row align-items-center">
                            <input
                              // onChange={handleUcinSearch}
                              onKeyPress={handleKeyPress}
                              value={serachUcin}
                              onChange={(e) => setUcinSearch(e.target.value)}
                              style={{
                                height: "2.3rem",
                                color: "gray",
                                borderRadius: "0.3rem",
                                outline: "none",
                                width: "26rem",
                                border: "0.7px solid lightgray",
                              }}
                              placeholder="Search..."
                            />
                            <div className="d-flex flex-row align-items-center justify-content-center">
                              <button
                                type="submit"
                                style={{
                                  backgroundColor: "rgb(126, 110, 228)",
                                  width: "70px",
                                  height: "35px",
                                  color: "white",
                                  textAlign: "center",
                                  borderRadius: "0.3rem",
                                }}
                              >
                                Search
                              </button>
                            </div>
                          </div>
                        </div>

                        <Table style={{ position: "relative", top: "1.3rem" }}>
                          <TableHead sx={{ borderTop: "1px solid lightgray" }}>
                            <TableRow>
                              <TableCell
                                align={"start"}
                                style={{
                                  minWidth: 0,
                                  fontSize: 14,
                                  fontWeight: "600",
                                }}
                              >
                                Name
                              </TableCell>
                              <TableCell
                                align={"left"}
                                style={{ top: 57, fontWeight: "600" }}
                              >
                                Mobile
                              </TableCell>

                              <TableCell
                                align={"left"}
                                style={{ top: 21, fontWeight: "600" }}
                              >
                                Aadhar
                              </TableCell>
                              <TableCell
                                align={"left"}
                                style={{ top: 21, fontWeight: "600" }}
                              >
                                Pan
                              </TableCell>

                              <TableCell
                                align={"left"}
                                style={{ top: 21, fontWeight: "600" }}
                              >
                                Address
                              </TableCell>

                              <TableCell
                                align={"left"}
                                style={{
                                  top: 21,
                                  fontWeight: "600",
                                  width: 18,
                                }}
                              >
                                Action
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {customerData.map((item) => (
                              <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={item.Id}
                              >
                                <TableCell
                                  align={"start"}
                                  style={{ top: 57, fontSize: 12 }}
                                >
                                  {item.customerName}
                                </TableCell>
                                <TableCell
                                  align={"start"}
                                  style={{ top: 57, fontSize: 12 }}
                                >
                                  <button
                                    style={{
                                      fontSize: 10.7,
                                      border: "2px solid rgb(126, 110, 228)",
                                      backgroundColor: "white",
                                      borderRadius: "1.2rem",
                                      cursor: "auto",
                                    }}
                                  >
                                    <FaMobileAlt
                                      style={{
                                        marginRight: 3,
                                        color: "rgb(126, 110, 228)",
                                        marginTop: "-0.16rem",
                                        fontSize: 9,
                                      }}
                                    />
                                    {item.mobileNumber}
                                  </button>
                                </TableCell>

                                <TableCell
                                  align={"start"}
                                  style={{ top: 57, fontSize: 12 }}
                                >
                                  {item.aadharNo}
                                </TableCell>
                                <TableCell
                                  align={"start"}
                                  style={{ top: 57, fontSize: 12 }}
                                >
                                  {item.panNo}
                                </TableCell>
                                <TableCell
                                  align={"start"}
                                  style={{ top: 57, fontSize: 12 }}
                                >
                                  {item.address}
                                </TableCell>
                                <TableCell
                                  align={"start"}
                                  style={{
                                    top: 57,
                                    fontSize: 12,
                                    color: "rgb(126, 110, 228) ",
                                  }}
                                >
                                  <div style={{ display: "flex" }}>
                                    <Badge
                                      bg="success"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        handleClickOpenCustomerCancelForm(),
                                          setId(item.id);
                                      }}
                                      type="button"
                                    >
                                      Cancel
                                    </Badge>
                                  </div>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </Form>
                    </div>
                  </TabPanel>
                </TabContext>
              </Box>
            {/* </DialogContent>
          </Dialog>
        </div> */}
      </div>
      <CustomerDetailsModal
        ucin={selectedUCIN}
        show={modalShow}
        handleClose={handleCloseModal}
      />

      <Dialog
        style={{ borderTop: "12px solid rgb(126, 110, 228)" }}
        maxWidth="xl"
        open={openCutomerCancelForm}
        onClose={handleCloseCustomerCancelForm}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <div style={{ width: "37rem" }}>
            <Form
              noValidate
              validated={ValidatedCustomerForm}
              onSubmit={formValidationForCustomerCancel}
            >
              <div className="mb-4 mt-1">
                <Typography
                  style={{
                    textAlign: "center",
                    fontWeight: "500",
                    color: "rgb(126, 110, 228)",
                    fontSize: 21,
                  }}
                >
                  Cancel Customer
                </Typography>
              </div>
              <Row className="mb-3">
                <Form.Group as={Col} md="6" controlId="validationCustom01">
                  <Typography style={{ color: "gray", fontSize: 13 }}>
                    Date of Cancel *
                  </Typography>
                  <Form.Control
                    required
                    onChange={(e) => setDataForCustomerCancel(e.target.value)}
                    type="datetime-local"
                    style={{ padding: "0.42rem", marginBottom: 14 }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please Enter Valid Name
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="12" controlId="validationCustom01">
                  <Typography style={{ color: "gray", fontSize: 13 }}>
                    Comment Box
                  </Typography>
                  <Form.Control
                    re
                    onChange={(e) =>
                      setCommentForCustomerCancel(e.target.value)
                    }
                    required
                    as="textarea"
                    style={{ padding: "0.42rem", marginBottom: 14 }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please Enter Valid Permanent Address
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <div className="d-flex">
                <div>
                  <Button
                    type="submit"
                    style={{
                      width: "9rem",
                      height: "2.3rem",
                      backgroundColor: "rgb(126, 110, 228)",
                      color: "white",
                      marginRight: 3,
                    }}
                  >
                    Submit
                  </Button>
                </div>
                <div>
                  <Button
                    onClick={() => {
                      setOpenCustomerCancelForm(false);
                      setValidatedCustomerCancelForm(false);
                    }}
                    style={{
                      width: "9rem",
                      height: "2.3rem",
                      backgroundColor: "gray",
                      color: "white",
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </DialogContent>
      </Dialog>
      {customerDataOfDocumentation.map((item) => (
        <Modal
          size="lg"
          show={modalShowForDocuemnts}
          onHide={handleCloseModalForDocuments}
          centered
          style={modalStyle}
        >
          <Modal.Header closeButton>
            <Modal.Title>Customer Details</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>
              <strong>Customer Name:</strong> {item.customerName}
            </p>
            <p>
              <strong>UCIN:</strong> {item.ucin}
            </p>

            <p>
              <strong>Aadhar No:</strong> {item.aadharNo}
            </p>
            {/* <img src={item.aadharImg}/> */}
            <img src={item.aadharImg}  style={{height:'160px',width:'250px'}}/>
            <p>
              <strong>PAN No:</strong> {item.panNo}
            </p>
            {/* <img src={item.panImg}/> */}
            <img src={item.panImg}  style={{height:'160px',width:'250px'}}/>
          </Modal.Body>

          <Modal.Footer>
            <Modal.Footer>
              {/* <ReactToPrint
              trigger={() => <Button variant="primary">Print</Button>}
              content={() => componentRef.current}
            /> */}
              <Button
                variant="secondary"
                onClick={() => handleAttachToMail(item)}
              >
                Attach to Mail
              </Button>
              <Button
                variant="secondary"
                onClick={handleCloseModalForDocuments}
              >
                Close
              </Button>
            </Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModalForDocuments}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      ))}
    </>
  );
}

export default CustomerSales;
