import React, { useState } from 'react';
import { Button, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

function RegisterCompany() {
  const [companyName, setCompanyName] = useState('');
  const [companyPrefix, setCompanyPrefix] = useState('');
  const [adminEmail, setAdminEmail] = useState('');
  const [adminPassword, setAdminPassword] = useState('');
  const [error, setError] = useState('');
  const [companyLogo, setCompanyLogo] = useState(null);

  const navigate = useNavigate();

  const handleLogoChange = (e) => {
    setCompanyLogo(e.target.files[0]);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const formData = new FormData();
    formData.append('companyName', companyName);
    formData.append('companyPrefix', companyPrefix); // Even if not used in backend
    formData.append('companyLogo', companyLogo);
    formData.append('adminEmail', adminEmail);
    formData.append('adminPassword', adminPassword);
  
    try {
      const response = await axios.post(
        'https://buildfastvapi.emedha.in/account-setting/api/v1/api/register',
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );
      console.log('Registration Successful:', response.data);
      navigate('/login');
    } catch (err) {
      console.error('Error Response:', err.response?.data || err.message);
      setError(err.response?.data?.error || 'Registration failed. Please try again.');
    }
  };
  


  return (
<div className="card login-main-container" style={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, borderRadius: '0.1rem', border: 'none' }}>
  {/* Header Background */}
  <div className="card" style={{ height: '18rem', position: 'relative', justifyContent: 'center', alignItems: 'center', borderRadius: '0.04rem', border: 'none', marginBottom: 10 }}>
    <div className="loginBackground" style={{ opacity: 0.7, backgroundColor: 'black', height: '17rem', borderBottomLeftRadius: '0.7rem', borderBottomRightRadius: '0.7rem' }}></div>
  </div>

  {/* Registration Form */}
  <div className="col-6 mx-auto card auth-form-container" style={{ border: 'none', marginTop: 61, borderRadius: '1.4rem' }}>
    <div className="row p-1">
      <div className="col-8 mx-auto p-1">
        {/* Logo */}
        <img
          src="https://png.pngtree.com/template/20190926/ourmid/pngtree-home-repair-logo-with-maintenance-tools-and-house-construction-c-image_309799.jpg"
          width="81px"
          alt="Company Logo"
        />
        <br />
        <br />
        {/* Header Text */}
        <Typography style={{ fontSize: 19, fontWeight: 'bold', color: 'rgb(42, 49, 111)', marginTop: 12, marginBottom: 3 }}>Register New Company</Typography>
        <Typography style={{ fontSize: 13, color: 'rgb(42, 49, 111)', marginTop: 1, marginBottom: 17 }}>Create a new company account to get started</Typography>

        {/* Input Fields */}
        <form onSubmit={handleSubmit}>
          <label className="d-flex">Company Name <span style={{ color: 'red' }}>*</span></label>
          <input
            type="text"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
            required
            style={{ paddingLeft: 10, height: '2.6rem', border: '0.8px solid lightgray', borderRadius: '0.3rem', width: '100%', marginBottom: 25, outline: 'none' }}
            placeholder="Enter company name"
          />

          <label className="d-flex">Company Prefix <span style={{ color: 'red' }}>*</span></label>
          <input
            type="text"
            value={companyPrefix}
            onChange={(e) => setCompanyPrefix(e.target.value)}
            required
            style={{ paddingLeft: 10, height: '2.6rem', border: '0.8px solid lightgray', borderRadius: '0.3rem', width: '100%', marginBottom: 25, outline: 'none' }}
            placeholder="Enter company prefix"
          />

          <label className="d-flex">Company Logo <span style={{ color: 'red' }}>*</span></label>
          <input
            type="file"
            onChange={handleLogoChange}
            accept="image/*"
            required
            style={{ paddingLeft: 10, height: '2.6rem', border: '0.8px solid lightgray', borderRadius: '0.3rem', width: '100%', marginBottom: 25, outline: 'none' }}
          />

          <label className="d-flex">Admin Email <span style={{ color: 'red' }}>*</span></label>
          <input
            type="email"
            value={adminEmail}
            onChange={(e) => setAdminEmail(e.target.value)}
            required
            style={{ paddingLeft: 10, height: '2.6rem', border: '0.8px solid lightgray', borderRadius: '0.3rem', width: '100%', marginBottom: 25, outline: 'none' }}
            placeholder="Enter admin email"
          />

          <label className="d-flex">Admin Password <span style={{ color: 'red' }}>*</span></label>
          <input
            type="password"
            value={adminPassword}
            onChange={(e) => setAdminPassword(e.target.value)}
            required
            style={{ paddingLeft: 10, height: '2.6rem', border: '0.8px solid lightgray', borderRadius: '0.3rem', width: '100%', marginBottom: 25, outline: 'none' }}
            placeholder="Enter admin password"
          />

          {/* Submit Button */}
          <Button type="submit" variant="contained" style={{ marginBottom: 20, width: '100%', backgroundColor: 'rgb(44, 53, 131)' }}>
            Register Company
          </Button>
        </form>

        {/* Redirect to Login */}
        <div style={{ textAlign: 'center', marginTop: 10 }}>
          <Typography>
            Already registered?{' '}
            <Link to="/login" style={{ color: 'rgb(44, 53, 131)', textDecoration: 'none' }}>
              Login here
            </Link>
          </Typography>
        </div>

        {/* Error Message */}
        {error && <Typography style={{ color: 'red', marginTop: 10 }}>{error}</Typography>}
      </div>
    </div>
  </div>

  {/* Footer */}
  <div className="card" style={{ justifyContent: 'center', alignItems: 'center', padding: 10, border: 'none', marginTop: 28, backgroundColor: 'rgb(126, 110, 228)', borderRadius: '0.02rem' }}>
    <Typography style={{ marginBottom: 10, color: 'white', fontSize: 18 }}>@2023 Emedha Technologies All Rights Reserved.</Typography>
  </div>
</div>

  );
}

export default RegisterCompany;

