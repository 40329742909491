// import '../node_modules/bootstrap/dist/css/bootstrap.css'
// import './App.css';
// import { Route, Routes, BrowserRouter } from 'react-router-dom'
// import MainDashboard from './Container/Dashboard/Dashboard/Dashboard';
// import UserLoginPage from './Container/Component/Auth/Login/UserLoginPage';
// import ForgotPassworEmailSent from './Container/Component/Auth/PasswordSetup/PasswordEmailSend/ForgotPassworEmailSent';
// import UserSignupPage from './Container/Component/Auth/Signup/Signup';
// import CreateCustomer from './Container/Dashboard/CstmrManagement/Customers';
// import LandingPage from './Container/LandingPage/LandingPage';
// import CustomerDasboard from './CustomerDashboard/CustomerDashboard';


// function App() {
//   return (
//     <div className="App">
//       {
//         sessionStorage.getItem('login') && (
//           <BrowserRouter>
//             <MainDashboard></MainDashboard>
//           </BrowserRouter>
//         )
//       }
//       {
//         !sessionStorage.getItem('login') && !sessionStorage.getItem('customer') && (
//           <BrowserRouter>
//             <Routes>
//               <Route path='/' element={<UserLoginPage />} />
//               <Route path='/forgot-password' element={<ForgotPassworEmailSent />} />
//               {/* <Route path='/create-account' element={<UserSignupPage />} /> */}
//             </Routes>
//           </BrowserRouter>
//         )
//       }
//       {
//         sessionStorage.getItem('customer') && (
//           <BrowserRouter>
//             <Routes>
//               <Route path='/customer-dashboard' element={<CustomerDasboard />} />
//             </Routes>
//           </BrowserRouter>
//         )
//       }
//     </div>

//   );
// }

// export default App;



import '../node_modules/bootstrap/dist/css/bootstrap.css';
import './App.css';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import MainDashboard from './Container/Dashboard/Dashboard/Dashboard';
import UserLoginPage from './Container/Component/Auth/Login/UserLoginPage';
import ForgotPassworEmailSent from './Container/Component/Auth/PasswordSetup/PasswordEmailSend/ForgotPassworEmailSent';
import UserSignupPage from './Container/Component/Auth/Signup/Signup';
import CreateCustomer from './Container/Dashboard/CstmrManagement/Customers';
import LandingPage from './Container/LandingPage/LandingPage';
import CustomerDasboard from './CustomerDashboard/CustomerDashboard';
import Register from './Container/Component/Auth/Register/Register';

function App() {
  return (
    <div className="App">
      {/* If no session exists, show Register Page */}
      {
        !sessionStorage.getItem('login') && !sessionStorage.getItem('customer') && (
          <BrowserRouter>
            <Routes>
              {/* Registration Page as Initial Page */}
              <Route path="/" element={<Register/>} />
              {/* Login Page */}
              <Route path="/login" element={<UserLoginPage />} />
              <Route path="/forgot-password" element={<ForgotPassworEmailSent />} />
            </Routes>
          </BrowserRouter>
        )
      }
      {/* If Admin is logged in, show Main Dashboard */}
      {
        sessionStorage.getItem('login') && (
          <BrowserRouter>
            <MainDashboard />
          </BrowserRouter>
        )
      }
      {/* If Customer is logged in, show Customer Dashboard */}
      {
        sessionStorage.getItem('customer') && (
          <BrowserRouter>
            <Routes>
              <Route path="/customer-dashboard" element={<CustomerDasboard />} />
            </Routes>
          </BrowserRouter>
        )
      }
    </div>
  );
}

export default App;
